import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function YoutubeTimeline(props) {
    React.useEffect(() => {
    }, [props.exercises, props.currentTime, props.end])

    const determineColor = (time, index) => {
        if(props.end){ return false;}
        if(props.exercises.length -1 == index) {
             return props.currentTime >= time;
        }
        return props.currentTime >= time && props.currentTime < props.exercises[index+1].time;
    };

    const updateVideoTime = (time) => {
        props.timeCallback(time)
    };

    return (
        <Timeline align="right">
            <div className="put-right skip" style={{color: 'indianred',textAlign:'right'}}>
                SKIP TO <ArrowDropDownIcon style={{marginRight: '-6px'}}/></div>
            {props.exercises != undefined && props.playerReady ? props.exercises.map((ex, index) =>
                <TimelineItem><TimelineSeparator>
                    <TimelineDot variant="outlined" onClick={() => updateVideoTime(ex.time)}
                         color={determineColor(ex.time, index) ? 'primary' : 'default'}/>
                    <TimelineConnector /></TimelineSeparator>
                    <TimelineContent className={determineColor(ex.time, index) ? 'clickable timeline-title-glow' : 'clickable'}
                                     onClick={() => updateVideoTime(ex.time)} >{ex.name}<br/>{ex.s}x{ex.r}</TimelineContent></TimelineItem>
            ):[]}
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot className="unclickable"
                                 variant="outlined" color={props.end ? 'primary' : 'default'} />
                </TimelineSeparator>
                <TimelineContent className={props.end ? 'timeline-title-glow' : ''}>End</TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}