import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { areaActionCreators } from '../store/Areas';
import ImageMapper from './ImageMapperMod';
import isEqual from "react-fast-compare";
import {Card, Spinner} from 'react-bootstrap';
import MuscleDropdown from './MuscleDropdown';
import MuscleLabel from './MuscleLabel';
import Notification from './SlideUpNotification';
import YoutubeList from './YoutubeList';
import Footer from '../components/Footer';
import ModalLoad from '../components/ModalLoad';
import {useProgressiveImage, useProgressiveImg} from "../components/useProgressiveImage";
import './body.css';
import FadeIn from "react-fade-in";
import useCurrentWidth from "./useCurrentWidth";
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import { ScrollTo } from "react-scroll-to";
import {IconButton} from "@material-ui/core";

//https://www.npmjs.com/package/smooth-polyline

const Body = (props) => {
    const [hoveredArea, setHoveredArea] = React.useState(null);
    const [displayLabel, setDisplayLabel] = React.useState(true);
    const [displayInfo, setDisplayInfo] = React.useState(false);
    const [mapLoaded, setMapLoaded] = React.useState(false);
    const [mapHalfLoad, setMapHalfLoad] = React.useState(false);
    const [imgLoaded, setImgLoaded] = React.useState(false);
    const [cords, setCords] = React.useState("[");
    const [scaledAreas, setScaledAreas] = React.useState(null);
    const [selectedArea, setSelectedArea] = React.useState(null);
    const [highlightedArea, sethighlightedArea] = React.useState(null);
    const [dropdownSelect, setDropdownSelect] = React.useState(false);
    const [muscleImageWidth, setMuscleImageWidth] = React.useState(props.width);
    const [prevWindowWidth, setPrevWindowWidth] = React.useState(0);
    const [instructionsDisplayed, setInstructionsDisplayed] = React.useState(false);
    const [rotate, setRotate] = React.useState(false);
    const [next, setNext] = React.useState(false);
    const [scrolled, setScrolled] = React.useState(false);
    const [muscleGroup, setMusleGroup] = React.useState([]);
    const windowWidth = useCurrentWidth();
    //const srcBody = useProgressiveImage({ src:"./images/body7.svg", fallbackSrc: "./images/body7_medium.png"}); // progressive loading SVG

    React.useEffect(() => {
        if (props.areas.length === 0) {
            //setMuscleImageWidth(window.innerWidth < 690 ? 339 : 690);  //if change - update css -> insta-container, muscle_label
            props.requestAreas();
        }
        else {
            if (scaledAreas === null) {
                setScaledAreas({ name: "my-map", areas: scalePoly(props.areas, muscleImageWidth) });
            }
            // else if(prevWindowWidth != windowWidth) {
            //     const newImageWidth = windowWidth < 690 ? 339 : 690;
            //     setMuscleImageWidth(newImageWidth);
            //     setPrevWindowWidth(windowWidth);
            //     setScaledAreas({ name: "my-map", areas: scalePoly(props.areas, newImageWidth) });
            // }
        }

    }, [props.areas, windowWidth]); //only run this when props.areas change

    const reloadBody = () => {
        if (selectedArea !== null) {
            scaledAreas.areas.map(ar => { if (ar.name === selectedArea.name) { ar.preFillColor = props.preFillColor; } });
        }

        /**/highlightGroup([], true);
        setSelectedArea(null);
        setDisplayLabel(true);
        sethighlightedArea(null);
        setHoveredArea(null);
        //setMapLoaded(false);
    };

    const enterArea = (area) => {
        if (dropdownSelect && highlightedArea !== null && selectedArea === null) {
            scaledAreas.areas.map(ar => { if (ar.name === highlightedArea.name) { ar.preFillColor = props.preFillColor; } });
            setDropdownSelect(false);
            /**/highlightGroup([], true);
        }

        setHoveredArea(area);
    };

    const leaveArea = (area) => {
        setHoveredArea(null);
        setDisplayInfo(false);
        if (selectedArea === null) {
            scaledAreas.areas.map(ar => { if (ar.name === area.name) { ar.preFillColor = props.preFillColor; } });
            /**/highlightGroup([], true);
        }
    };

    const getTipPosition = (area) => {
        var offSet = (window.innerWidth - muscleImageWidth) / 2;
        return { top: `${parseInt(area.center[1])}px`, left: `${parseInt(area.center[0] + offSet)}px` };
    };

    const load = () => {
        if(mapHalfLoad) {
            setMapLoaded(true);
        }
        setMapHalfLoad(true);
    };

    const imgLoad = (e) => {
        //if(e.target.src.includes('body7.svg')) {
        if(e.target.src.includes('body7_medium.png')) {
            setImgLoaded(true);
        }
    };

    const highlightGroup = (muscles, clear) => {
        if (clear && muscleGroup.length > 0) {
            scaledAreas.areas.map(ar => { if (muscleGroup.includes(ar.name)) { ar.preFillColor = props.preFillColor; }});
            setMusleGroup([]);
        } else {
            setMusleGroup(muscles);
            scaledAreas.areas.map(ar => { if (muscles.includes(ar.name)) { ar.preFillColor = props.secondaryColour }});
        }
    };

    const clicked = (area) => {
        setInstructionsDisplayed(true);
        if (selectedArea !== null) {
            reloadBody();
        }

        if (highlightedArea && area.name === highlightedArea.name) {
            setDisplayLabel(false);
            setDisplayInfo(false);
            setSelectedArea(area);
            scaledAreas.areas.map(ar => { if (ar.name === area.name) { ar.preFillColor = props.hilightColour; } });
            return;
        }

        sethighlightedArea(area);
        scaledAreas.areas.map(ar => { if (ar.name === area.name) { ar.preFillColor = props.hilightColour; } });
        setHoveredArea(area);
        setDisplayInfo(true);
    };

    const moveOnImage = (evt) => {};
    const moveOnArea = (area, evt) => {};

    const clickedOutside = (evt) => {
        var coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
        setCords(cords + coords.x + "," + coords.y + ", ");
        setHoveredArea(null);
        if (highlightedArea !== null && selectedArea === null) {
            scaledAreas.areas.map(ar => { if (ar.name === highlightedArea.name) { ar.preFillColor = props.preFillColor; } });
            /**/highlightGroup([], true);
        }

        sethighlightedArea(null);
        setDisplayInfo(false);
    };

    const muscleCallback = (muscle) => {
        if (highlightedArea !== null && selectedArea === null) {
            scaledAreas.areas.map(ar => { if (ar.name === highlightedArea.name) { ar.preFillColor = props.preFillColor; } });
            /**/highlightGroup([], true);
        }

        reloadBody();
        var muscleArea = scaledAreas.areas.find(ar => ar.name === muscle);
        muscleArea.center = computeCenter(muscleArea);
        sethighlightedArea(muscleArea);
        scaledAreas.areas.map(ar => { if (ar.name === muscle) { ar.preFillColor = props.hilightColour; } });
        setHoveredArea(muscleArea);
        setDisplayInfo(true);
        setDropdownSelect(true);
    };

    const renderLoading = () => {
        return window.innerWidth >= 690 ? <div className='clearfix text-center space-top'>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div> : [];
    };
    const renderLoadingSmall = () => { return window.innerWidth < 690 ? <ModalLoad open={true} text="Loading muscles"/> :[];};
    const renderLoading2 = () => {
        return <div className="on-body-top spinner-dot">LOADING&nbsp;&nbsp;
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div></div>;
    };

    const doRotate =() => {
        setRotate(true);
        setTimeout(() => setRotate(false), 1000);
    };

    const scalePoly = (areas, imageWidth) => {
        if (areas.length === 0) {
            return areas;
        }

        var dummyImage = new Image();
        dummyImage.src = props.pic;
        var factor = (imageWidth / (dummyImage.width == 0 ? props.realWidth : dummyImage.width)); // dummyImage.width not working atm
        var newAreas = JSON.parse(JSON.stringify(areas)); //Deep copy
        for (var i = 0; i < areas.length; i++) {
            newAreas[i].coords = [];
            areas[i].coords.map(coord => newAreas[i].coords.push(parseInt(coord * factor)));
        }

        return newAreas;
    };

    const scrollStatus = () => {
        if(!scrolled && selectedArea){
            setScrolled(true);
            return true;
        }
        if(!selectedArea) {
            setScrolled(false);
        }
        return false;
    };

    return (
        <div className="body_all">
            <div className="outer_container outer_body">
                <ScrollTo>{({ scroll }) => (scrollStatus() && scroll({ x: 20, y: 500, smooth: true }))}</ScrollTo>
                <div className="body-background" style={{minHeight: `${selectedArea ? '0px':'63vh'}`}}>
                    <Notification displayInfo={mapLoaded && !instructionsDisplayed} location="top" margin="90px" message="Tap a muscle" exercise={false}/>
                    {hoveredArea && <Notification exercise={true} displayInfo={displayInfo} location="bottom" message="Double tap muscle for workouts" />}
                    <div className={next ? "body-slide next":"body-slide"}><div className={(!mapLoaded || !imgLoaded) ?
                        "container inner_body b_loading no-padding" :
                        "container inner_body b_loaded no-padding"}>
                        {scaledAreas && <ImageMapper src={props.pic} map={scaledAreas} width={muscleImageWidth}
                                                     onLoad={() => load()}
                                                     onClick={area => clicked(area)}
                                                     onMouseEnter={area => enterArea(area)}
                                                     onMouseLeave={area => leaveArea(area)}
                                                     onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
                                                     onImageClick={evt => clickedOutside(evt)}
                                                     onImageMouseMove={evt => moveOnImage(evt)} />}
                        {displayLabel && hoveredArea && <MuscleLabel displayLabel={displayLabel} hoveredArea={hoveredArea} displayInfo={displayInfo} getTipPosition={getTipPosition} />}
                        <img onLoad={(e) => imgLoad(e)} className={displayInfo ? "myShine gx-image2" : ""}data-name="gx-logo" src="./images/body7_medium.png" id="overlay_image" alt="body outline" style={{ width: `${muscleImageWidth}px` }} />
                        {/*<img onLoad={(e) => imgLoad(e)} className={displayInfo ? "myShine gx-image2" : ""}data-name="gx-logo" src={srcBody} id="overlay_image" alt="body outline" style={{ width: `${muscleImageWidth}px` }} />*/}
                    </div></div>
                    {(!mapLoaded || !imgLoaded) && renderLoading2()}
                    {(mapLoaded && imgLoaded) && <div className="on-body-top">
                        <IconButton onClick={()=>{doRotate(); setNext(!next);}} className={rotate?'icon-button flip-body rot':'icon-button flip-body'}
                            style={{marginLeft: '-10px'}}><FlipCameraAndroidIcon />
                        </IconButton></div>}
                    <div className="on-body">
                        {(!mapLoaded || !imgLoaded) && renderLoading()}
                        {mapLoaded && imgLoaded && <MuscleDropdown callback={muscleCallback}
                        muscle={highlightedArea == null ? null : highlightedArea.name} muscles={[...new Set(props.areas.map(a => a.name))]} />}
                    </div>
                </div>
                {selectedArea && <YoutubeList muscle={selectedArea.name} highlightGroup={highlightGroup}/>}
                {/*selectedArea && <Muscle muscleId={2} />*/}
                {mapLoaded && imgLoaded && <FadeIn className="bg-second" delay={500}><Footer/></FadeIn>}
            </div>
            {(!mapLoaded || !imgLoaded) && renderLoadingSmall()}
        </div>);
};

Body.defaultProps = {
    pic: './images/blank.png',
    // width: 339,
    width: 690,
    scaleFactor: 0.226,
    realWidth: 1500,
    hilightColour: 'indianred',
    secondaryColour: 'grey',
    preFillColor: ''
};

function computeCenter(area) {
    if (!area) return [0, 0];
    const n = area.coords.length / 2;
    const { y, x } = area.coords.reduce(
        ({ y, x }, val, idx) => {
            return !(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x };
        },
        { y: 0, x: 0 }
    );
    return [x, y];
}

function updateNotRequired(prevBody, nextBody) {
    return isEqual(prevBody.areas, nextBody.areas);
}

export default connect(
    state => state.areas,
    dispatch => bindActionCreators(areaActionCreators, dispatch)
)(React.memo(Body, updateNotRequired));
