import React from "react";
import checkBrowser from "./checkBrowser";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

const Cart = (props) => {
    const [count, setCount] = React.useState(Number('0'));

    React.useEffect(() => {
        setCount(props.cartCount);
    }, [props.cartCount])

    const updateTotalCartCount = () => {
        let count = 0;
        if(checkBrowser) {
            let i = 0;
            let key = "";
            for (i = 0; i <= localStorage.length - 1; i++) {
                key = localStorage.key(i);
                if(!isNaN(key)){
                    count = (Number(count) + Number(localStorage.getItem(key)));
                }
            }
        }

        return count;
    };

// return (<span className="fa-stack">
//     <FontAwesomeIcon className="fa-2x" icon={faShoppingCart} />
//     <strong className="fa-stack-1x" id="cart-count" style={{fontsize:'80%', color: 'white'}}>
//         &nbsp;&nbsp;&nbsp;{props.cartCount}</strong>
// </span>);

    return (<Badge badgeContent={count == -1 ? updateTotalCartCount() : count} color="error">
        <LocalMallIcon style={{fontsize:'large'}}/>
    </Badge>);
};
export default Cart;

