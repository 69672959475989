import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {Card, CardColumns, ListGroup, ListGroupItem, Col, Spinner, Row} from 'react-bootstrap';
import {useHistory} from "react-router-dom";
import * as QueryString from "query-string";
import FadeIn from "react-fade-in";
import {faHashtag, faCheck, faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getBaseUrl, getHeaders} from "./Api";
import ModalLoad from "./ModalLoad";
import InfoModal from "./InfoModal";

//http://localhost:3000/confirmation?checkoutId=56&orderId=25&referenceId=66&transactionId=6756756
const PayConfirmation = (props) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isEmailLoading, setIsEmailLoading] = React.useState(true);
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [emailResponseStatus, setEmailResponseStatus] = React.useState(null);
    const [checkoutId, setCheckoutId] = React.useState();
    const [transactionId, setTransactionId] = React.useState();
    const [referenceId, setReferenceId] = React.useState(null);
    const [customerEmail, setCustomerEmail] = React.useState("");
    const [productOrder, setProductOrder] = React.useState(null);
    const [date, setDate] = React.useState("");
    const [total, setTotal] = React.useState("");
    const [promoDiscount, setPromoDiscount] = React.useState("");
    const [address1, setAddress1] = React.useState("");
    const [address2, setAddress2] = React.useState("");
    const [city, setCity] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [merchEmail, setMerchEmail] = React.useState("powa.ldn@gmail.com");
    const [emailSent, setEmailSent] = React.useState(false);
    const [deliveryPrice, setDeliveryPrice] = React.useState(0);
    const [error, setError] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        if (referenceId === null) {
            let UrlQueryStrings = history.location.search;
            if(QueryString != undefined) {
                const queryValues = QueryString.parse(UrlQueryStrings);
                setCheckoutId(queryValues.checkoutId);
                setTransactionId(queryValues.transactionId);
                setReferenceId(queryValues.referenceId);
            }
        } else if(referenceId != null && responseStatus == null) {
            submit();
        }
    }, [referenceId, responseStatus, emailSent, error])

    const renderLoading = () => {
        return ((isLoading || isEmailLoading) && window.innerWidth >= 690) ?
            <div className='clearfix text-center' style={{marginTop: '5px'}}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div> : ((isLoading || isEmailLoading) && window.innerWidth < 690) ? <ModalLoad open={true} text="Completing order" />:[];
    };

    const sendEmail = (targetEmail, name) => {
        setIsEmailLoading(true);
        fetch(getBaseUrl() + props.emailUrl, {
            method: 'POST',
            body: JSON.stringify({
                recipientName: name,
                senderName: "powa",
                subject: "Thank you for your order (#" + referenceId +")",
                text: referenceId,
                to: targetEmail,
                orderId: referenceId
            }),
            headers: getHeaders(),
        }).then(response => {setIsEmailLoading(false); setEmailResponseStatus(response.status);
        setEmailSent(response.status == 201 ? true:false)}).
        catch((err) => {
            setIsEmailLoading(false);
        });
    };

    const submit = () => {
        setIsLoading(true);
        fetch(getBaseUrl() + props.orderUrl + new URLSearchParams({ id: referenceId , transaction: transactionId}), {
            method: 'GET',
            headers: getHeaders(),
        }).then(response => {const json = response.json(); setResponseStatus(response.status); return json;}).then(data => {
            setIsLoading(false);
            if(data.dateCreated != undefined && data.dateCreated != "") {
                setDate(data.dateCreated);
                setTotal(data.totalOrderPrice);
                setCustomerEmail(data.email);
                setProductOrder(data.orderProducts);
                setPromoDiscount(data.discount);
                setAddress1(data.address1);
                setAddress2(data.address2);
                setCity(data.city);
                setZip(data.postCode);
                setDeliveryPrice(data.deliveryPrice);
                clearLocalStorage();
                sendEmail(data.email, data.firstName + " " + data.lastName); //ENABLE THIS
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
            setError(true);
        });
    };

    const failModal = () => {
        return error ? <InfoModal
            show={true} text={"Transaction not found, or order already processed."} subText={"Get in contact if failure persists."}
            title={"Oops something went wrong.."} callback={()=>{}}/> : [];
    };

    const clearLocalStorage = () => {
        let i = 0;
        let item;
        let key = "";
        const removeList = [];
        for (i = 0; i <= localStorage.length-1; i++) {
            key = localStorage.key(i);
            if(!isNaN(key) || key.startsWith("promo-")) { removeList.push(key);}
        }
        for(item in removeList){
            localStorage.removeItem(removeList[item]);
        }
        props.navbarCallback(0);
    };

    const productListItem = (order) => {
        return <ListGroupItem>
            <Row><Col>{order.quantity} x {order.product.name} - {order.product.size}</Col><Col>£{order.totalPrice.toFixed(2)}</Col></Row>
            {order.product.discount != 0 ? <Row className="text-muted" style={{fontSize: 'small'}}><Col>{order.product.discount}% discount</Col>
                <Col>-£{(order.product.discount * order.totalPrice * 0.01).toFixed(2)}</Col></Row>:[]}
        </ListGroupItem>;
    };
    const deliveryListItem = () => {return <ListGroupItem><Row><Col>Delivery (Royal Mail)</Col><Col>£{deliveryPrice.toFixed(2)}</Col></Row></ListGroupItem>;};

    return (<div style={{width: '100%'}}><FadeIn delay={0}><Card style={{textAlign: 'center', width: '100%'}}>
        <Card.Header>Confirmation</Card.Header>
        {error ? <Card.Body style={{minHeight: '65vh'}}><Card.Title>Order could not be confirmed</Card.Title>
            <Card.Subtitle>Order <b style={{color: 'indianred'}}>#{referenceId}</b></Card.Subtitle>
            <Card.Text className="text-muted"><small>Check your email to see if this order has already been confirmed.</small></Card.Text>
            <Card.Text>Please get in contact at <i> <a className="required-field" href="mailto:powa.ldn@gmail.com">{merchEmail}</a></i></Card.Text></Card.Body>:[]}
        {!error ? <ListGroup variant="flush" className="confirm-table">
            <ListGroup.Item><Card.Title>Order Placed Successfully <FontAwesomeIcon icon={faCheck}/></Card.Title></ListGroup.Item>
            <ListGroup.Item><Card.Text><>Thank you for your order. </><br/>
                <>You will receive a confirmation email shortly at <b>{customerEmail}</b>.</><br/>
                If you have questions about your order, please contact us at
                <i> <a className="required-field" href="mailto:powa.ldn@gmail.com">{merchEmail}</a></i>.</Card.Text>
            <Card.Title>Order <b style={{color: 'indianred'}}>#{referenceId}</b></Card.Title></ListGroup.Item>
            {renderLoading()}<ListGroup.Item>{(responseStatus == 200) ?
                <><FadeIn><Row><Col style={{marginLeft: '3px'}}><Row>Shipping Address</Row>
                    <Row><small className="text-muted">{address1}</small></Row>
                    <Row><small className="text-muted">{address2}</small></Row>
                    <Row><small className="text-muted">{city}</small></Row>
                    <Row><small className="text-muted">{zip}</small></Row></Col><Col>
                    <Card.Text>Order Date: <i style={{marginLeft: '5px'}}>{date}</i></Card.Text></Col></Row>
                    {!emailSent ? <i style={{color:'indianred'}}>Please wait for the order email to be sent...</i> : <i style={{color:'indianred'}}>Order email sent.</i>}
                    {!emailSent && !isEmailLoading ? <i style={{color:'indianred'}}>Failed to send confirmation email</i> :[]}
                    <ListGroup className="list-group-flush confirm-table">
                        <ListGroupItem>Products:</ListGroupItem>
                        {productOrder != null ? productOrder.map((order, i) => productListItem(order)):[]}
                        {deliveryListItem()}
                        {promoDiscount != 1 ? <ListGroupItem>
                            <Row className="text-muted"><Col>Promo Discount</Col><Col>-{(promoDiscount*100)}%</Col></Row></ListGroupItem> :[]}
                    </ListGroup>
                    <ListGroup.Item><Card.Text><Row><Col>
                        Order Total</Col><Col><i style={{marginLeft: '5px'}}>£{total}</i></Col></Row></Card.Text></ListGroup.Item>
                </FadeIn></> : []}</ListGroup.Item>
        </ListGroup>:[]}</Card></FadeIn>{failModal()}
</div>);
};
export default PayConfirmation;

PayConfirmation.defaultProps = {
    orderUrl: 'ordercomplete?',
    emailUrl: 'emailhtml'
};