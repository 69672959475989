// List of weeks with intensity progress level
//show progress of a week/ group of weeks in a progress bar
//link through to week view
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {useHistory, withRouter} from "react-router-dom";
import FadeIn from "react-fade-in";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import WorkIcon from "@material-ui/icons/Work";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {LinearProgressWithLabel} from "./ProgressLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'red',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    text: {
        textAlign: 'center'
    },
    title: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    doubleColumn: {
        flexBasis: '66.66%',
    },
    progress: {
        width: '100%',
        float: 'right',
        textAlign: 'right'
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const Program = (props) => {
    const [muscle, setMuscle] = React.useState("");
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {

    }, []);


    const handleExpand = (e, expanded) => { };

    const goToWorkout = () => {history.push("/programs?id=" + 5 + "?workout=" + 1); };

    const dayList = () => <FadeIn transitionDuration="500"><List className={classes.root}>
        <ListItem button divider onClick={() => goToWorkout()}>
            <ListItemText primary="Monday" secondary="Jan 9, 2014" />
            <div className={classes.progress}>{ 50 > 0 ? <LinearProgressWithLabel value={50} />:"Not started"}</div>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <CheckCircleIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <ListItem button divider>
            <ListItemText primary="Tuesday" secondary="Jan 7, 2014" />
            <div className={classes.progress}>{ 0 > 0 ? <LinearProgressWithLabel value={0} />:"Not started"}</div>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <CheckCircleIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <ListItem button divider>
            <ListItemText primary="Wednesday" secondary="July 20, 2014" />
            <div className={classes.progress}>{ 0 > 0 ? <LinearProgressWithLabel value={0} />:"Not started"}</div>
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <CheckCircleIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    </List></FadeIn>;

    return (<React.Fragment><div className={classes.text}><FadeIn transitionDuration="500"><div className={classes.title}>{props.program.title}</div></FadeIn>
        <FadeIn delay="300"><p><small>12 Week program</small></p></FadeIn>
        <FadeIn delay="500"><p><small>4 Week intensity intervals</small></p></FadeIn></div>
        <FadeIn delay="800" transitionDuration="250"><div className={classes.root}>
            <Accordion defaultExpanded onChange={(e, expanded) => handleExpand(e, expanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <div className={classes.column}>
                        <Typography className={classes.heading}>Week 1</Typography>
                    </div>
                    <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>Program introduction</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.doubleColumn}>
                        {dayList()}
                    </div>
                    <div className={clsx(classes.column, classes.helper)}>
                        <Typography variant="caption">
                            Select your destination of choice
                            <br />
                            <a href="#secondary-heading-and-columns" className={classes.link}>
                                Learn more
                            </a>
                        </Typography>
                    </div>
                </AccordionDetails>
                <Divider />
                <AccordionActions>
                    <Button size="small">Cancel</Button>
                    <Button size="small" color="primary">
                        Save
                    </Button>
                </AccordionActions>
            </Accordion>
        </div></FadeIn>
    </React.Fragment>);
};
export default withRouter(Program);


