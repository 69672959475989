import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import Snackbar from '@material-ui/core/Snackbar';

const Search = (props) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [open, setOpen] = React.useState(false);

    const updateTerm = (term) => {
        setSearchTerm(term);
        if (term == "") {
            props.searchCallback(term);
        }
    };

    const search = () => {
        setOpen(false);
        props.searchCallback(searchTerm)
    };

    const handleClick = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};

    return (<>
        <input
            className="form-control py-2 custom-focus prod-search"
            type="search"
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => { updateTerm(e.target.value);}}
            id="example-search-input"/>
            <span className="input-group-append nopop-span">
                <button className="btn btn-outline-secondary search-button" type="button" onClick={() => search()}>
                    <i className="fa fa-search"></i>
                </button>
            </span>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center',}}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}><><input className="form-control py-2 custom-focus" type="search" value={searchTerm} placeholder="Search"
            onChange={(e) => { updateTerm(e.target.value);}}
            id="popup-search"/><button className="btn btn-dark pop-search-button" type="button" onClick={() => search()}>
            <i className="fa fa-search"></i></button></></Snackbar>
        <span className="input-group-append pop-span">
                <button className="btn btn-outline-secondary search-button" type="button" onClick={handleClick}>
                    <i className="fa fa-search"></i>
                </button>
            </span></>);
};
export default Search;

