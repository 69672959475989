import {getBaseUrl} from "../components/Api"
const requestAreasType = 'REQUEST_AREAS';
const receiveAreasType = 'RECEIVE_AREAS';
const initialState = { areas: [], isLoading: false };

export const areaActionCreators = {
    requestAreas: () => async (dispatch) => {
        dispatch({ type: requestAreasType });

        const url = getBaseUrl() + `areas`;
        const response = await fetch(url);
        const areas = await response.json();

        dispatch({ type: receiveAreasType, areas });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestAreasType:
            return {
                ...state,
                isLoading: true
            };
        case receiveAreasType:
            return {
                ...state,
                areas: action.areas,
                isLoading: false
            };
        default:
            return state;
    }

    return state;
};
