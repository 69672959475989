import React from "react";
import {Overlay, Tooltip, Button, Popover, Carousel} from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const OverlayButton = (props) => {
    const [show, setShow] = React.useState(false);
    const target = React.useRef(null);
    const [overlayText, setOverlayText] = React.useState("");
    const [overlayBodyText, setOverlayBodyText] = React.useState("");

    React.useEffect(() => {
        setOverlayText(props.overlayText);
        setOverlayBodyText(props.overlayBodyText);
    }, []);

    const callFunc = () => {
        props.func(props.funcParam);
        setShow(!show);
    };

    return (<React.Fragment>
        <IconButton aria-label="delete" onClick={() => setShow(!show)}
                    ref={target} style={{fontSize: "large"}} className="icon-button cart-button">
            <DeleteForeverIcon/>
        </IconButton>
        {/*<Button variant="danger" ref={target} onClick={() => setShow(!show)}>*/}
        {/*{props.text}*/}
        {/*</Button>*/}
        <Overlay target={target.current} show={show} placement="left">
            {(props) => (
                <Popover {...props}>
                <Popover.Title as="h3">{overlayText}</Popover.Title>
                <Popover.Content>
                    <p>{overlayBodyText}</p>
                    <IconButton aria-label="close"  className="icon-button" onClick={() => callFunc()}>
                        <CheckCircleOutlineIcon />
                    </IconButton>
                    <IconButton aria-label="close"  className="icon-button" onClick={() => setShow(!show)}>
                        <HighlightOffIcon />
                    </IconButton>
                </Popover.Content>
                </Popover>
            )}
        </Overlay></React.Fragment>);
};

export default OverlayButton;

