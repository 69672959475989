import React from 'react';
import { CardColumns, Container, Row } from 'react-bootstrap';
import Product from './Product';
import {faFrown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Products = (props) => {
    React.useEffect(() => {
    }, [props.products, props.searchTerm]);

    const productList = {
        width: '100%',
        padding: '0px',
        margin: '0px'
    };

    const search = () => {
        const searchArray = props.searchTerm.toLowerCase().split(" ");
        const resultArray = [];

        const distinctProds = [];
        props.products.forEach(function(product) {
            let i = distinctProds.findIndex(x => x.name == product.name);
            if (i <= -1) { distinctProds.push(product); }});

        for (const subTerm of searchArray) {
            resultArray.push(...distinctProds.filter(item =>
                item.name.toLowerCase().includes(subTerm) ||
                item.description.toLowerCase().includes(subTerm) ||
                item.type.toLowerCase().includes(subTerm) ||
                item.subtype.toLowerCase().includes(subTerm) ||
                item.fit.toLowerCase().includes(subTerm) ||
                item.material.toLowerCase().includes(subTerm) ||
                item.colour.toLowerCase().includes(subTerm)));
        }

        const counter = {};
        resultArray.forEach(function(obj) {
            const key = obj.name;
            counter[key] = (counter[key] || 0) + 1;
        });

        const result = Object.keys(counter).sort(function(a, b) {
            return counter[b] - counter[a];
        });

        //result is a array product names in order of display so we have to find them in props.products and return them!
        const finalProds = []
        for (const prodName of result) {
            finalProds.push(...props.products.filter(item => item.name == prodName));
        }
        return finalProds;
    };

    // TODO: move all this to backend
    const filterProduct = () => {
        let filteredProducts = props.searchTerm == "" ? props.products : search();
        if(props.searchTerm == "") {
            if (props.gender != null && props.gender.length > 0) { filteredProducts = filteredProducts.filter(x => props.gender.includes(x.gender)); }
            if (props.category != null && props.category.length > 0) { filteredProducts = filteredProducts.filter(x => props.category.includes(x.type)); }
            if (props.style != null && props.style.length > 0) { filteredProducts = filteredProducts.filter(x => props.style.includes(x.fit)); }
            if (props.type != null && props.type.length > 0) { filteredProducts = filteredProducts.filter(x => props.type.includes(x.subtype)); }
        }
        const productNames = []
        const finalProducts = []
        for (const item of filteredProducts) {
            if(!productNames.includes(item.name)){
                productNames.push(item.name);
                finalProducts.push(item);
            }
        }

        return finalProducts;
    };

    return (
        <Container fluid className='no-padding' style={{minHeight: '70vh'}}>
            <Row style={productList}>
                <CardColumns className="products">
                    {filterProduct().length == 0 ? <div className="no-results">Sorry, no products were found <FontAwesomeIcon icon={faFrown} className="second-col"/></div> :
                    filterProduct().map((product,index) =>
                        <Product
                            index={index}
                            cartCallback={props.cartCallback}
                            product={product}
                            gotoCart={props.gotoCart}
                            sizesByName={props.sizesByNames.get(product.name)}
                            colorsByNames={props.colorsByNames}
                            selectedProductCallback={props.selectedProductCallback}/>)}
                </CardColumns>
            </Row>
        </Container>);
};

export default Products;