//has a view of programs for different goals, length of time, fitness level
//use can enter access code and email to get list of their programs returned which click through to program
//in program list you can see the progress bar of how much has been completed
//my programs section -> need to add some sort of user authentication
//Program ->
//--title
//--endGoal
//--weekCount
//--type (muscle building, toning etc)
//--advanceLevel
//--weeks[] -> days[] -> workouts[]-> excercises[]

//restart program - keep previous data
//export/print program -with previous data

//use https://material-ui.com/components/grid-list/ for programs overview
//use https://material-ui.com/components/breadcrumbs/ to navigate back and fro to my programs
// override back action https://www.codegrepper.com/code-examples/javascript/react+router+override+back+button
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import DeleteIcon from '@material-ui/icons/Delete';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import checkBrowser from "../components/checkBrowser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {programActionCreators} from "../store/Programs";
import Navigator from "./Navigator";
import ProgramsOverview from "./ProgramsOverview";
import Program from "./Program";
import {getBaseUrl} from "../components/Api";
import {useHistory, withRouter} from "react-router-dom";
import FadeIn from "react-fade-in";
import * as QueryString from "query-string";
import {LinearProgressWithLabel} from "./ProgressLabel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: 'red',
    },
    title: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    progress: {
        width: '100%',
        float: 'right',
        textAlign: 'right'
    }
}));

const Programs = (props) => {
    const [programs, setPrograms] = React.useState([]);
    const [myPrograms, setMyPrograms] = React.useState();
    const [ urlSearch, setUrlSearch ] = React.useState("");
    const [singleProgram, setSingleProgram ] = React.useState();
    const [displaySingle, setDisplaySingle] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        // if (props.programs.length === 0) { props.requestPrograms(getBaseUrl() + props.programsUrl); } else {
        //     setPrograms(props.programs);
        // }
        // if (myPrograms === undefined && programs.length != 0) {
        //     props.requestPrograms(getBaseUrl() + props.myProgramsUrl + new URLSearchParams({ accessCode: "blah", email: "bla@gmail.com" }));
        // }
        // if (programs.length != 0 && props.programs.length != 0 && props.programs[0].weeks.length != 0) { setMyPrograms(props.programs) }
        // if (QueryString != undefined && myPrograms != undefined) {
        //     let UrlQueryStrings = history.location.search;
        //     const queryValues = QueryString.parse(UrlQueryStrings);
        //     if (queryValues.id != undefined &&
        //         queryValues.id != "" &&
        //         myPrograms.filter(p => p.id == queryValues.id).length > 0) {
        //         updateSelectedProgram(Number(queryValues.id));
        //     } else if (queryValues.id == undefined || queryValues.id == "") {
        //         setDisplaySingle(false);
        //     }
        // }
        /**/if (QueryString != undefined) {
        /**/let UrlQueryStrings = history.location.search;
        /**/const queryValues = QueryString.parse(UrlQueryStrings);
        /**/if (queryValues.id != undefined &&
        /**/        queryValues.id != "") {
        /**/        updateSelectedProgram(Number(queryValues.id));
        /**/    }
        /**/}

        return history.listen(location => {
            if (history.action === 'PUSH') { //forward
                setUrlSearch(location.search);}

            if (history.action === 'POP') { //back
                setUrlSearch(location.search);} });
    }, [props.programs, programs, myPrograms, urlSearch, singleProgram, displaySingle]);

    const goToProgram = () => {history.push("/programs?id=" + 5); };

    const updateSelectedProgram = (id) => {
        if(!displaySingle) { setDisplaySingle(true);}
        if(singleProgram == undefined || singleProgram.id != id) {
            setSingleProgram({title: "arms for days", weeks: [], advanceLevel: 'high', id: id});//setSingleProgram(myPrograms.find(p => p.id === id));
        }
    };

    const programUpdateCallback = () => {};
    const displayProgram = () => <Program program={singleProgram} update={programUpdateCallback} />;
    const displayHome = () => <React.Fragment>
        <FadeIn delay="300" transitionDuration="500"><ProgramsOverview title="Available Programs"/></FadeIn>
        <FadeIn delay="800" transitionDuration="250"><div className={classes.title}>My Programs</div></FadeIn>
        <FadeIn delay="1050" transitionDuration="1000"><List className={classes.root}>
            <ListItem button divider onClick={() => goToProgram()}>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Photos" secondary="Jan 9, 2014" />
                <div className={classes.progress}>{ 50 > 0 ? <LinearProgressWithLabel value={50} />:"Not started"}</div>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem button divider>
                <ListItemAvatar>
                    <Avatar>
                        <WorkIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Work" secondary="Jan 7, 2014" />
                <div className={classes.progress}>{ 0 > 0 ? <LinearProgressWithLabel value={0} />:"Not started"}</div>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem button divider>
                <ListItemAvatar>
                    <Avatar>
                        <BeachAccessIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Vacation" secondary="July 20, 2014" />
                <div className={classes.progress}>{ 0 > 0 ? <LinearProgressWithLabel value={0} />:"Not started"}</div>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List></FadeIn></React.Fragment>;

    return (<div><FadeIn><Navigator/></FadeIn>
        {(displaySingle && singleProgram != undefined) ? displayProgram() : displayHome()}
    </div>);
};
export default connect(
    state => state.programs,
    dispatch => bindActionCreators(programActionCreators, dispatch)
)(withRouter(Programs));

Program.defaultProps = {
    programs: [{id: 5, title: 'Summer Arms', goal: 'grow 2 inches on biceps', type: 'muscle building', weekCount: 12,
    startDate: new Date().toString(), endDate: new Date().toString(), level: 'advanced', description: '12 Week program to really get on it.',
        progress: 50,
    weeks:[{active: true, progress: 50,  completed: false, dateCompleted: "",  days:[{
        name: 'Monday', completed: false, dateCompleted: "", active: true, progress: 50, workouts: [{id:1, exercises:[] }]},
            {name: 'Tuesday', completed: false, dateCompleted: "", active: true, progress: 50, workouts: [{id:1, exercises:[] }]},
            {name: 'Wednesday', completed: false, dateCompleted: "", active: false, progress: 0, workouts: [{id:1, exercises:[] }]},
            {name: 'Thursday', completed: false, dateCompleted: "", active: false, progress: 0, workouts: [{id:1, exercises:[] }]},
        ]}]
    },
    ],
    myProgramsUrl: "programs?",
    programsUrl: "programs", // just return names no detail (nothing under weeks[])
};

