import {getHeaders} from "../components/Api";
const requestProductType = 'REQUEST_PRODUCTS';
const receiveProductType = 'RECEIVE_PRODUCTS';
const initialState = { products: [], isLoading: false };

export const productActionCreators = {
    requestProducts: (baseurl) => async (dispatch) => {
        dispatch({ type: requestProductType });
        const url = baseurl;// + `/api/products`;

        const response = await fetch(url, {
            method: "GET",
            headers: getHeaders(),
            /*"mode" : "no-cors"*/});
        const products = await response.json();
        dispatch({ type: receiveProductType, products });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestProductType:
        return {
            ...state,
            isLoading: true
        };
        case receiveProductType:
        return {
            ...state,
            products: action.products,
            isLoading: false
        };
        default:
            return state;
    }

    return state;
};
