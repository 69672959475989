import React from "react";
import YouTube from 'react-youtube';
import FadeIn from "react-fade-in";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import {Container, Row, Col } from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import YoutubeTimeline from "./YoutubeTimeline";
//https://developers.google.com/youtube/iframe_api_reference#Playback_controls

const YoutubeEmbed = (props) => {
    const [checked, setChecked] = React.useState(false);
    const [player, setPlayer] = React.useState(null);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [ticker, setTicker] = React.useState(undefined);
    const [end, setEnd] = React.useState(false);
    const [playerReady, setPlayerReady] = React.useState(false);

    React.useEffect(() => {
        return ticker == undefined ? {} : () => clearInterval();
    }, [props.info], checked, player)

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const opts = {
        height: '390',
        width: '100%',
        display: 'table',
        margin: '0 auto',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    const setTimeCallback = (time) => {
        if(player != null) {
            player.seekTo(Number(time), true);
            if(player.playerInfo.playerState != 1) {
                setCurrentTime(time);
            }
        }
    };

    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        setPlayer(event.target);
        event.target.pauseVideo();
        setPlayerReady(true);
    };

    const _onPlay = (event) => {
        setEnd(false);
        setTicker(setInterval(() =>
       {setCurrentTime(player == null ?
           event.target.playerInfo.currentTime : player.playerInfo.currentTime);},
           1000));
    };

    const _onEnd = (event) => { clearInterval(ticker); setEnd(true);};
    const _onPlause = (event) => { clearInterval(ticker);};

    const highlightOthers = (e, expanded) => {
        if (expanded && props.info.exerciseList.length != 0) {
            props.highlightGroup(props.info.primaryMuscles.filter(m => m != props.muscle), false);
        } else if(!expanded) {
            props.highlightGroup([], true);
        }
    };

    return(props.info != undefined ? <div className="center-content youtube-vid">
        <Accordion onChange={(e, expanded) => highlightOthers(e, expanded)}>
            <AccordionSummary className="ani-accord"id={props.id}>
                <div className="ani-div"><p className="ani-p">&mdash; <span className="ani-span">
            {props.info.title} <FitnessCenterIcon className="ani-icon"/></span> &mdash;</p></div>
            </AccordionSummary>
            {props.info.exerciseList.length != 0 ? <Container><Row className="ani-columns">
                <Col className="ani-card youtube-timeline put-right">
                    <YoutubeTimeline end={end} timeCallback={setTimeCallback} playerReady={playerReady} exercises={props.info.exerciseList} currentTime={currentTime}/></Col>
                <Col className="ani-card youtube-card">
                    <YouTube videoId={props.info.videoId} opts={opts} onReady={_onReady} onPlay={_onPlay} onPause={_onPlause} onEnd={_onEnd}/>
                </Col>
            </Row></Container>:[]}
            </Accordion>
    </div>:[]);
};
export default YoutubeEmbed;


