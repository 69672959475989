import React from 'react';
import { FormControl, Dropdown, DropdownButton } from 'react-bootstrap';

const MuscleDropdown = (props) => {
    const [selectedMuscle, setSelectedMuscle] = React.useState(null);

    React.useEffect(() => {
        setSelectedMuscle(props.muscle);
    }, [props.muscle]);

    const muscleSelected = (muscle) => {
        setSelectedMuscle(muscle);
        props.callback(muscle);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a href="" ref={ref} onClick={(e) => {e.preventDefault(); onClick(e);}}> {children} &#x25bc;</a>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = React.useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <DropdownButton className="center-content" variant="outline-none" id="muscle-drop" title={selectedMuscle == null ? "MUSCLES" : selectedMuscle}>
            <Dropdown.Item eventKey={props.muscles.indexOf(selectedMuscle)} active>{selectedMuscle == null ? "select:" : selectedMuscle}</Dropdown.Item>
            <Dropdown.Divider />
            {props.muscles.map((muscle, i) => <Dropdown.Item onSelect={() => muscleSelected(muscle)} eventKey={i}>{muscle}</Dropdown.Item>)}
        </DropdownButton>
    );
}

export default MuscleDropdown;