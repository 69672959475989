import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStore, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import Cart from './Cart';
import Search from './Search';
import './NavMenu.css';

const NavMenu = (props) => {
    React.useEffect(() => {
    }, [props.cartCount]);

    return <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" fixed="top" className="fade-from-bottom">
        <Navbar.Brand className="brand-logo" href="#home">
            <img
                src="./images/powa_white.png"
                // width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Logo"
            />{' '}
        </Navbar.Brand>
        <div className="input-group col-md-4 navbasket-collapse search-wrap-s"><Search searchCallback={props.searchCallback}/></div>
        <Navbar.Brand className="navbasket-collapse basket-wrap-s" >
            <Nav className="navbar-custom">
                <Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>
            </Nav>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-between">
            <Nav className="navbar-custom">
                <Nav.Link href="#home"><FontAwesomeIcon icon='home'/> Home</Nav.Link>
                <Nav.Link href="/shop"><FontAwesomeIcon icon={faStore}/> Shop</Nav.Link>
                <Nav.Link href="/contact"><FontAwesomeIcon icon={faCommentAlt}/> Contact</Nav.Link>
                <Nav.Link href="/workout"><FontAwesomeIcon icon='dumbbell' /> Workouts</Nav.Link>
                {/*<Nav.Link href="/programs"><FontAwesomeIcon icon='dumbbell' /> Coaching</Nav.Link>*/}
            </Nav>
        </Navbar.Collapse>
        <div style={{width: '50%'}} className="input-group col-md-4 navbasket-right"><Search searchCallback={props.searchCallback}/></div>
        <Navbar.Brand className="navbasket-right">
            <Nav className="navbar-custom">
                <Nav.Link href="/basket" ><Cart cartCount={props.cartCount}/></Nav.Link>
            </Nav>
        </Navbar.Brand>
    </Navbar>;
}

export default NavMenu;
