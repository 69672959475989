import React from 'react';
import $ from 'jquery';
import { Card, Carousel, Container, Col, Image, Row } from 'react-bootstrap';
import {IconButton} from "@material-ui/core";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import {useProgressiveImage} from "./useProgressiveImage";

const ProductGallery = (props) => {
    const img1Ref = React.useRef();
    const img2Ref = React.useRef();
    const img3Ref = React.useRef();
    const img4Ref = React.useRef();
    const [index, setIndex] = React.useState(0);
    const [zoomed1, setZoomed1] = React.useState(false);
    const [zoomed2, setZoomed2] = React.useState(false);
    const [zoomed3, setZoomed3] = React.useState(false);
    const [zoomed4, setZoomed4] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [imageReceived, setImageReceived] = React.useState(0); //To ensure the component is updated

    React.useEffect(() => {
    }, []);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const handleZoom = (refff, zoomin, currentZoom, setCurrentZoom) => {
        const $el = $(refff.current);
        if(zoomin && !currentZoom){
            setCurrentZoom(true);
            $el.toggleClass('transform-in');
        } else if(!zoomin && currentZoom){
            setCurrentZoom(false);
            $el.toggleClass('transform-in');
        }
    };

    const getSrc = (index) => {
        return props.src1.split('.').join(`${index}.`).substring(1);
    };

    const getSrcThumb = (index) => {
        return props.src1.split('.').join(`${index}.`).substring(1).replace(".j", "_small.j");
    };

    return (<Container><Row><Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item>
            <div className="img-wrapper"><img
                ref={img1Ref}
                className="d-block w-100 prodimg transform"
                src={getSrc(1)}
                alt="First slide"
            /></div>
            <Carousel.Caption>
                <IconButton aria-label="zoom_in" onClick={() => handleZoom(img1Ref, true, zoomed1, setZoomed1)} style={{fontSize: "large"}} className="icon-button zoomin"><ZoomInIcon/></IconButton>
                <IconButton aria-label="zoom_out" onClick={() => handleZoom(img1Ref, false, zoomed1, setZoomed1)} style={{fontSize: "large"}} className="icon-button zoomout"><ZoomOutIcon/></IconButton>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <div className="img-wrapper"><img
                ref={img2Ref}
                className="d-block w-100 prodimg transform"
                src={getSrc(2)}
                alt="Second slide"
            /></div>
            <Carousel.Caption>
                <IconButton aria-label="zoom_in" onClick={() => handleZoom(img2Ref, true, zoomed2, setZoomed2)} style={{fontSize: "large"}} className="icon-button zoomin"><ZoomInIcon/></IconButton>
                <IconButton aria-label="zoom_out" onClick={() => handleZoom(img2Ref, false, zoomed2, setZoomed2)} style={{fontSize: "large"}} className="icon-button zoomout"><ZoomOutIcon/></IconButton>
            </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
            <div className="img-wrapper"><img
                ref={img3Ref}
                className="d-block w-100 prodimg transform"
                src={getSrc(3)}
                alt="Third slide"
            /></div>
            <Carousel.Caption>
                <IconButton aria-label="zoom_in" onClick={() => handleZoom(img3Ref, true, zoomed3, setZoomed3)} style={{fontSize: "large"}} className="icon-button zoomin"><ZoomInIcon/></IconButton>
                <IconButton aria-label="zoom_out" onClick={() => handleZoom(img3Ref, false, zoomed3, setZoomed3)} style={{fontSize: "large"}} className="icon-button zoomout"><ZoomOutIcon/></IconButton>
            </Carousel.Caption>
        </Carousel.Item>
        {/*not needed - start*/}
        <Carousel.Item>
            <div className="img-wrapper"><img
                ref={img4Ref}
                className="d-block w-100 prodimg transform"
                src={getSrc(4)}
                alt="First slide"
            /></div>
            <Carousel.Caption>
                <IconButton aria-label="zoom_in" onClick={() => handleZoom(img4Ref, true, zoomed1, setZoomed4)} style={{fontSize: "large"}} className="icon-button zoomin"><ZoomInIcon/></IconButton>
                <IconButton aria-label="zoom_out" onClick={() => handleZoom(img4Ref, false, zoomed1, setZoomed4)} style={{fontSize: "large"}} className="icon-button zoomout"><ZoomOutIcon/></IconButton>
            </Carousel.Caption>
        </Carousel.Item>
        {/*not needed - end*/}
    </Carousel></Row>
    <Row>
        <Col className="no-pads put-content-center">
            <Image onClick={(e) => handleSelect(0, e)} className="thumbnail link_element" src={getSrcThumb(1)} thumbnail />
        </Col>
        <Col className="no-pads put-content-center">
            <Image onClick={(e) => handleSelect(1, e)} className="thumbnail link_element" src={getSrcThumb(2)} thumbnail />
        </Col>
        <Col className="no-pads put-content-center">
            <Image onClick={(e) => handleSelect(2, e)} className="thumbnail link_element" src={getSrcThumb(3)} thumbnail />
        </Col>
        <Col className="no-pads put-content-center">
            <Image onClick={(e) => handleSelect(3, e)} className="thumbnail link_element" src={getSrcThumb(4)} thumbnail />
        </Col>
    </Row></Container>);
};

export default (ProductGallery);


ProductGallery.defaultProps = {
    productImageUrl: 'https://wger.de/api/v2/exerciseimage/?exercise='
};