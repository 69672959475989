import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {Button, Modal } from 'react-bootstrap';
import {useHistory} from "react-router-dom";

const InfoModal = (props) => {
    const [show, setShow] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        setShow(props.show);
    }, [props.title, props.text, props.show])

    const goToContact = () => {
        handleClose();
        history.push("/contact");
    }

    const handleClose = () => {  setShow(false); props.callback();};
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.text}<div style={{textAlign:"left", width: '70%'}}><small>{props.subText}</small></div>
                    {props.listMessages != undefined && props.listMessages != null ? Object.entries(props.listMessages).map(m =>
                        <div className="text-muted"><small>{m[1]}</small></div>) : []}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={() => goToContact()}>
                        Contact
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default InfoModal;