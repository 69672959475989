const env = "prod";

export const getApi = () => {return "a9b3f05f-59d9-4668-addf-f5a943cad0b2"};

export function getBaseUrl() {
    return env == 'prod' ? "https://powaldn.co.uk:443/api/" : "http://localhost:8087/api/";
    //return env == 'prod' ? "http://92.205.20.224:80/api/" : "http://localhost:8087/api/";
};

export const getHeaders = () => {
    return env == 'prod' ? {'Content-Type': 'application/json'} : {"Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'};
};