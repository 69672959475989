import React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Shop from './components/Shop';
import CheckoutAddress from './components/CheckoutAddress';
import ProductDetails from './components/ProductDetails';
import OrderStatus from './components/OrderStatus';
import Contact from './components/Contact';
import CookiePolicy from './components/CookiePolicy';
import Body from './body/Body';
import Programs from './coaching/Programs';
import Checkout from './components/Checkout';
import { connect } from "react-redux";
import { toggleCookie, toggleDisplay } from "./store/Cookie";
import { useCookies } from 'react-cookie';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faDumbbell, faInfo, faClipboardList, faHome, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import TransactionResult from "./components/TransactionResult";
import {useHistory} from "react-router-dom";
import PayConfirmation from "./components/PayConfirmation";
import $ from 'jquery';

//https://fontawesome.com/icons?d=gallery&m=free
library.add(fab, faCheckSquare, faCoffee, faDumbbell, faInfo, faClipboardList, faHome, faInfoCircle)

const App = (props) => {
    const [cartCount, setCartCount] = React.useState(-1);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [cookies, setCookie, removeCookie] = useCookies();
    const history = useHistory();

    React.useEffect(() => {
    }, [searchTerm, cartCount, props.policyDisplayed]);

    const navbarCallback = (count) => {
        setCartCount(count);
    }

    const searchCallback = (term) => {
        setSearchTerm(term);
        if(term != ""){
            history.push('/shop');
        }
    }

    function toggleCookies(enable) {
        if(!enable) {
            for (let cookie in cookies) {
                removeCookie(cookie);
            }
        }

        localStorage.setItem('allowCookies', enable.toString());
    }

    // close nave on body click
    $(document).click(function(e) {
        if (!$(e.target).is('a')) {
            $('.collapse').removeClass('show');
        }
    });


    return (<Layout cartCount={cartCount} searchCallback={searchCallback} toggleCookies={toggleCookies}>
        <Route exact path='/' component={Home} />
        <Route path='/shop' render={() => <Shop navbarCallback={navbarCallback} searchTerm={searchTerm}/>} />
        <Route path='/orderStatus' component={OrderStatus} />
        <Route path='/checkout' component={CheckoutAddress} />
        <Route path='/productDetails' component={ProductDetails} />
        <Route path='/contact' component={Contact} />
        <Route path='/cookiePolicy' component={CookiePolicy} />
        <Route path='/payment' component={Checkout} />
        <Route path='/confirmation' render={() => <PayConfirmation navbarCallback={navbarCallback}/>} />
        <Route path='/workout' component={Body} />
        {/*<Route path='/programs' component={Programs} />*/}
        <Route path='/basket' render={() => <Shop navbarCallback={navbarCallback} searchTerm="" displayCart={true}/>} />
    </Layout>);
};

export default App;

// export default connect(state => mapToProps(state), { toggleCookie, toggleDisplay })(App);
// const mapToProps = (state) => {
//     return {policyDisplayed: state.cookie.policyDisplayed};
// }