import React from 'react';
import { connect } from 'react-redux';
import HomeCarousel from './HomeCarousel';
import {Row, Col} from 'react-bootstrap';
import anime from 'animejs';
import './Home.css';

const Home = props => {
    const [text] = React.useState("Reach.| Your.|- Full.| Potential");
    const myLetter = React.useRef();
    const myWrapper = React.useRef();

   React.useEffect(() => {
       const myNode = myWrapper.current;
       const letter = myLetter.current;
       anime.timeline({loop: true})
           .add({
               targets: [myNode, letter],
               translateX: [40,0],
               translateZ: 0,
               opacity: [0,1],
               easing: "easeOutExpo",
               duration: 2200,
               delay: (el, i) => 500 + 30 * i
           }).add({
           targets: [myNode, letter],
           translateX: [0,-30],
           opacity: [1,0],
           easing: "easeInExpo",
           duration: 2100,
           delay: (el, i) => 100 + 30 * i
       });
   }, [])

    return (<div style={{minWidth: '100%'}}>
        <HomeCarousel/>
        <div className="home-text centered">
            <Row><Col><h1 className="second-col welcome">Welcome to</h1></Col></Row>
            <Row><Col style={{overflow:'hidden !important'}} className="glowcol no-pads">
                    <p className="glowtext-no" style={{fontFamily: 'mylogo', fontSize: '100px', marginBottom: '-35px', marginTop: '-40px'}}>
                        PØWA</p></Col></Row>
            <Row><Col><div style={{marginBottom: '10px'}} id="ProgressBar"><div id="Progress"></div></div></Col></Row>
        </div>
        <div ref={myWrapper} id="home-quote" className="ml12 home-text centered" style={{marginLeft: '5%'}}>
            {[...text].map(letter => (
                <><span className="letter" ref={myLetter}>{(letter != '|' && letter != '-') ? letter: []}
                </span>{letter == '|' ? <br className="break-quoute-small"/> :(letter == '-' ? <br className="break-quoute"/>:[])}</>
            ))}
        </div>
    </div>);
};

export default connect()(Home);
