import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Form, Col, Row, Container, Spinner } from 'react-bootstrap';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import {IconButton, Tooltip} from "@material-ui/core";
import {useHistory, Link, Route} from "react-router-dom";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ProductDetails from './ProductDetails';
import CountInput from './CountInput';
import FadeIn from 'react-fade-in';
import {useProgressiveImage} from "./useProgressiveImage";

const Product = (props) => {
    const [productDropQuantity, setProductDropQuantity] = React.useState(Number('0'));
    const [inCart, setInCart] = React.useState(false);
    const [selectedSizeId, setSelectedSizeId] = React.useState();
    const history = useHistory();
    const srcProg = useProgressiveImage({ src:props.product.pictureUrl.split('.').join('1.').substring(1),
        fallbackSrc: props.product.pictureUrl.split('.').join('1.').substring(1).replace(".j", "_small.j")});

    const updateDropQuantity = (count) => {
        if (count != null) {
            setProductDropQuantity(Number(count));
        }
    };

    const goToProduct = () => {history.push("/shop?id=" + props.product.id); }
    const goToGivenProduct = (id) => { history.push("/shop?id=" + id); }

    const updateinCart = (value) => {
        setInCart(value);
        console.log(props.product.id + "  " + productDropQuantity + "  " + !value + " sizeid: " + (selectedSizeId == undefined? props.sizesByName[0].id : selectedSizeId));
        props.cartCallback((selectedSizeId == undefined? props.sizesByName[0].id : selectedSizeId), productDropQuantity, !value, false);
        if(value == false){
            setProductDropQuantity(Number('0'));
        }
    };

    const calculateDiscount = (price, discount, stock) => {
        if (discount != 0){
            return <Card.Text><del style={{color: "grey"}}>£{price}</del> £{(Number(price) * (100 - Number(discount)) * Number('0.01')).toFixed(2)}
            <small style={{color: "green"}}>&nbsp;-{discount}%</small> {stock == 0 ? <b className="second-col">&nbsp;&nbsp;OUT OF STOCK</b> : []}</Card.Text>;
        }

        return <Card.Text>£{price} {stock == 0 ? <b className="second-col">&nbsp;&nbsp;OUT OF STOCK</b> : []}</Card.Text>;
    };

    const  sizeChanged = (event) => {
        setSelectedSizeId(event.target.value);
    };

    const availColours = () => {
        const availableColours = props.colorsByNames.get(props.product.name.split('-')[0])
            .filter((v,i,a)=>a.findIndex(t=>(t.color === v.color))===i).map((idColor, index) =>
            <IconButton id={index} aria-label="color" className="icon-button" style={{color: idColor.color}}
                        onClick={() => goToGivenProduct(idColor.id)}><ColorLensIcon/></IconButton>
        );
        return availableColours;
    };

    const getSrc = () => {
        return srcProg;
    };

    return (<FadeIn transitionDuration="1000"><React.Fragment>
        <Route path='/productDetails' render={(props) => <ProductDetails {...props} text="Hello, " cartCallback={props.cartCallback}/>}
        />
        <Card>
        {/*<Card.Header className="link_element" onClick={() => goToProduct()}>*/}
        {/*    {props.product.name}*/}
        {/*</Card.Header>*/}
            <div className="img-hover-zoom-prod">
                <Card.Img className="link_element"  onClick={() => goToProduct()} variant="top" src={getSrc()} /></div>
        <Card.Body className="shop-product-card-body">
            <Container>
                <Card.Title className="link_element" onClick={() => goToProduct()}>{props.product.name} <ColorLensIcon style={{color: props.product.colour}}/></Card.Title>
                <Row>{calculateDiscount(props.product.price, props.product.discount, props.product.stock)}</Row>
                { inCart ? [] : <Row>
                    <Col className="nospace_element"><CountInput max={props.product.stock} value={productDropQuantity}
                        updateDropQuantity={updateDropQuantity} disabled={props.product.stock == 0}/></Col>
                    {/*<Col className="nospace_element">*/}
                    {/*    <input id={props.product.id} type="number" min="0" max={props.product.stock} value={productDropQuantity} disabled={props.product.stock == 0}*/}
                    {/*   className="form-control quantity_drop" onChange={event => updateDropQuantity(event.target.value)}/>*/}
                    {/*</Col>*/}
                    <Col className="nospace_element">
                        <Form.Control as="select" disabled={props.product.stock == 0} onChange={sizeChanged} className="my-1 mr-sm-2" id="inlineFormCustomSelectPref" custom>
                        {Object.entries(props.sizesByName).map(([key,value], index) =>
                        <option disabled={value.stock == 0} value={value.id}>{value.size}</option>)}
                    </Form.Control></Col>
                    <Col className="nospace_element"><Tooltip title="Add to basket" aria-label="Add"><IconButton aria-label="add_cart" className="icon-button disabled_element"
                        style={{color: `${Number(productDropQuantity) <= Number('0') ? 'grey':'green'}`}}
                        disabled={Number(productDropQuantity) <= Number('0')}
                        onClick={() => updateinCart(true)}><AddShoppingCartIcon /></IconButton></Tooltip></Col>

                </Row> }
                <div class="col-12">
                    { inCart ? <><Tooltip title="Remove from basket" aria-label="Remove"><IconButton aria-label="add_cart" className="icon-button" style={{color: "red"}}
                                             onClick={() => updateinCart(false)}><RemoveShoppingCartIcon/>
                        </IconButton></Tooltip><Button style={{marginLeft: '10px'}} onClick={() => props.gotoCart()} variant="outline-dark">GO TO BASKET</Button></> : [] }
                </div>
            </Container>
        </Card.Body>
        <Card.Footer>
            {availColours()}
        </Card.Footer>
    </Card></React.Fragment></FadeIn>
    );};

export default connect()(Product);
