import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import './body.css';
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import TouchAppIcon from '@material-ui/icons/TouchApp';

const Notification = (props) => {
    const vertical = props.location; //'bottom';
    const horizontal = 'center';

    const dumbbell = (
        <FitnessCenterIcon/>
    );

    const tap = (
        <TouchAppIcon/>
    );

    return (
        // <Fade in={props.displayInfo} timeout="800" >
        // <div className={"notifier " + (props.displayInfo? "myShine gx-image2": "")} id="example-fade-text">
        //     Click on muscle again for workouts
        // </div></Fade>
        <div>
            <Snackbar
                style={{marginTop: `${props.margin != undefined ? props.margin : '0px'}`}}
                anchorOrigin={{ vertical, horizontal }}
                TransitionComponent={Slide}
                open={props.displayInfo}
                message={props.message}
                key={vertical + horizontal}
                action={props.exercise ? dumbbell : tap}/>
        </div>
    );
}

export default connect()(Notification);