import React from "react";
import clsx from 'clsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Button, Container, Row} from "react-bootstrap";
import {
    Tooltip,
    SwipeableDrawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Checkbox,
    IconButton
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import FilterMultiselect from "./FilterMultiselect";
import $ from 'jquery';

const Filter = (props) => {
    const [anchor, setAnchor] = React.useState('left');
    const [open, setOpen] = React.useState(false);
    const [gender, setGender] = React.useState("");
    const [category, setCategory] = React.useState("");
    const [type , setType] = React.useState("");
    const [style , setStyle] = React.useState("");
    const [filterParams, setFilterParams] = React.useState(null);
    const useStyles = makeStyles({
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
    });
    const classes = useStyles();

    React.useEffect(() => {
        if(filterParams == null && props.products != undefined && props.products.length != 0){
            populateOptions();
        }
    }, [filterParams, props.products]);

    const list = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation">
            <IconButton aria-label="close" className="icon-button" onClick={toggleDrawer(false)}>
                <HighlightOffIcon />
            </IconButton>
            <List>
                {['Gender', 'Category', 'Type', 'Style'].map((text, index) => (
                    <ListItem button key={text}>
                        {/*<ListItemIcon>{index % 2 === 0 ? <FilterListIcon /> : <FilterListIcon />}</ListItemIcon>*/}
                        {text == 'Gender' ? <ListItemText primary={text}/> :
                            <FilterMultiselect names={filterParams == null ? [] :
                                filterParams[text]} initList={getList(text)} callback={updateFilter} criteria={text}/>}
                        <ListItemText>{text == 'Gender' ? genderList() : []}</ListItemText>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <Button style={{width: '120px', marginLeft: 'calc(50% - 60px)', marginTop: '10px'}}
                    onClick={() => clearFilter()} variant="outline-dark">CLEAR FILTER</Button>
            {/*<List>*/}
            {/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
            {/*        <ListItem button key={text}>*/}
            {/*            <ListItemIcon>{index % 2 === 0 ? <FilterListIcon /> : <FilterListIcon />}</ListItemIcon>*/}
            {/*            <ListItemText primary={text} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*</List>*/}
        </div>
    );

    const genderList = () => {
        return <Container style={{fontSize: 'small'}}>
         <Row><Checkbox checked={gender.includes("W")} onChange={(e) => { updateFilter(e.target.checked, "W", "Gender"); }} color="default" size="small"/> Women</Row>
        <Row><Checkbox checked={gender.includes("M")} onChange={(e) => { updateFilter(e.target.checked, "M", "Gender"); }} color="default" size="small"/> Men</Row>
        {/*<Row><Checkbox checked={gender.includes("U")} onChange={(e) => { updateFilter(e.target.checked, "U", "Gender"); }} color="default" size="small"/> Unisex</Row>*/}
        </Container>;
    };

    const updateFilter = (add, value, text) => {
        let option;
        let funct;
        switch(text) {
            case 'Gender':
                option = gender;
                funct = setGender;
                break;
            case 'Type':
                option = type;
                funct = setType;
                break;
            case 'Category':
                option = category;
                funct = setCategory;
                break;
            case 'Style':
                option = style;
                funct = setStyle;
                break;
            default:
                option = [];
                funct = (blah) => {};
        }
        if(!add && option.includes('.' + value)) {
            funct(option.replace('.' + value, ''));
        } else if(add && !option.includes('.' + value)) {
            funct(option.concat('.' + value));
        }
    };

    const populateOptions = () => {
        let nothing = null;
        const genders = []; const types = []; const categories = []; const styles = []; const map = {};
        for (const item of props.products) {
            genders.includes(item.gender) ? nothing = null : genders.push(item.gender);
            types.includes(item.subtype) ? nothing = null : types.push(item.subtype);
            categories.includes(item.type) ? nothing = null : categories.push(item.type);
            styles.includes(item.fit) ? nothing = null : styles.push(item.fit);
        }

        map['Gender'] = genders;
        map['Type'] = types;
        map['Category'] = categories;
        map['Style'] = styles;
        setFilterParams(map);
    };

    const getList = (optionText) => {
        if(filterParams == null) {
            return [];
        }
        let option;
        switch(optionText) {
            case 'Gender':
                option = gender;
                break;
            case 'Type':
                option = type;
                break;
            case 'Category':
                option = category;
                break;
            case 'Style':
                option = style;
                break;
            default:
                option = [];
        }
        const filterList = filterParams[optionText];
        const result = [];
        for (const item of filterList) {
            if(option.includes(item)) {
                result.push(item);
            }
        }
        return result;
    };

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return; }
        setOpen(open);
        if(!open) {props.filter(getList('Gender', gender),
            getList('Category'),
            getList('Style'),
            getList('Type'));}
    };

    const clearFilter = (event) => {
        setGender("");
        setCategory("");
        setType("");
        setStyle("");
        $(".MuiInput-input").html("");
    };

    return (<><Tooltip title="Filter products" aria-label="Filter"><Button className="center-content"
            style={{marginTop: '10px', float: 'right'}}
            onClick={toggleDrawer(true)}
            variant="outline-dark">FILTER <FilterListIcon style={{marginLeft: '5px'}}/></Button></Tooltip>
        <SwipeableDrawer anchor={anchor} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
            {list(anchor)}
        </SwipeableDrawer>
    </>);
};
export default Filter;

