import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";
import Slide from '@material-ui/core/Slide';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {postActionCreators} from "../store/Posts";
import {getBaseUrl} from "../components/Api"

const YoutubeList = (props) => {
    const [muscle, setMuscle] = React.useState("");

    React.useEffect(() => {
        if (props.posts.length === 0 || muscle != props.muscle) {
            setMuscle(props.muscle);
            props.requestPosts(getBaseUrl() + props.postsUrl + new URLSearchParams({ muscle: props.muscle, primary: false }));
        }
    }, [props.posts, props.muscle]);

    return( <Slide direction="up" in={true}><div>
        {props.posts.length != 0 ? props.posts.map((workout, index) =>
            <YoutubeEmbed info={workout} id={index} highlightGroup={props.highlightGroup} muscle={props.muscle}/>
        ):[]}
        {props.posts.length === 0 ? <YoutubeEmbed info={props.coming} id={1000}/> :
            <YoutubeEmbed info={props.more} id={1000}/>}</div>
    </Slide>);
};
export default connect(
    state => state.posts,
    dispatch => bindActionCreators(postActionCreators, dispatch)
)(YoutubeList);

YoutubeList.defaultProps = {
    postsUrl: "posts?",
    more: {title:"More Coming soon...", videoId:"", exerciseList: [] },
    coming: {title:"Coming soon...", videoId:"", exerciseList: [] },
};

