import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {Row, Spinner, Overlay, Tooltip} from 'react-bootstrap';
import {getBaseUrl, getHeaders} from "./Api"
import ModalLoad from "./ModalLoad";

const PromoCode = (props) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [promoWarning, setPromoWarning] = React.useState("");
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const target = React.useRef(null);

    React.useEffect(() => {
        let i = 0;
        let key = "";
        for (i = 0; i <= localStorage.length-1; i++) {
            key = localStorage.key(i);
            if(key.startsWith("promo-")) {
                props.promoCallback(key.split("-")[1], localStorage.getItem(key));
                break;
            }
        }
    }, []);

    const updateTerm = (term) => {
        setSearchTerm(term);
        if (term == "") {
            setShow(false);
        }
    };

    const renderLoading = () => {
        return (isLoading && window.innerWidth >= 690) ?
            <div className='clearfix text-center' style={{marginRight: '1vw'}}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div> : (isLoading && window.innerWidth < 690) ? <ModalLoad open={true}/>:[];
    };

    const submit = () => {
        setShow(false);
        if(searchTerm.length < 3 || searchTerm.match(/^\d/)){
            setPromoWarning("Incorrect promo code format.");
            setShow(true);
            return;
        }
        props.clearPromos();
        setIsLoading(true);
        fetch(getBaseUrl() + props.promoUrl + new URLSearchParams({ code: searchTerm.toString() }), {
            method: 'GET',
            headers: getHeaders(),
        }).then(response => {
            const json = response.json(); setResponseStatus(response.status); return json;}).then(discount => {
            setIsLoading(false);
            if(discount != undefined && discount != 0){
                localStorage.setItem("promo-" + searchTerm, discount);
                props.promoCallback(searchTerm, discount);
            } else {
                setPromoWarning("Promo code not found.");
                setShow(true);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    return (<Row className="search_row" style={{marginBottom: '2vh'}}>
        {renderLoading()}
        <input className="form-control py-2 custom-focus promo-input"
            type="search"
            value={searchTerm}
            required
            placeholder="Enter promo code"
            onChange={(e) => { updateTerm(e.target.value);}}
            id="example-search-input"/>
        <span className="input-group-append">
                <button ref={target} disabled={isLoading ? "disabled" : ""} className="btn btn-outline-secondary"
                        type="button" onClick={() => submit()}>USE
                </button>
                  <Overlay target={target.current} show={show} placement="top">
                      {(props) => (<Tooltip id="overlay-promo" {...props}>{promoWarning}</Tooltip>)}</Overlay>
            </span></Row>);
};
export default PromoCode;
PromoCode.defaultProps = {
    promoUrl: 'promo?'
};
