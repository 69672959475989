import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import FadeIn from 'react-fade-in';
import {useProgressiveImage, useProgressiveImg} from "./useProgressiveImage";
import {useHistory} from "react-router-dom";

const HomeCarousel = (props) => {
    const [index, setIndex] = React.useState(-1);
    const src1 = useProgressiveImage({ src:'/images/banner1_full.jpeg', fallbackSrc: '/images/banner1_small.jpeg'});
    const src2 = useProgressiveImage({ src:'/images/banner2_full.jpeg', fallbackSrc: '/images/banner2_small.jpeg'});
    const src3 = useProgressiveImage({ src:'/images/banner3_full.jpeg', fallbackSrc: '/images/banner3_small.jpeg'});
    const [src11, { blur1 }] = useProgressiveImg('/images/jumphome1.png', '/images/jumphome1_small.png'); //width 300px
    const history = useHistory();

    React.useEffect(() => {}, [index]);
    const slid = (index) => { setIndex(index); };
    const firstSlideImageLoaded = () => { setIndex(0); };

    return (
        <Carousel className="home-caro-img-wrap" pause={false} onSlid={(index) => slid(index)}>
            <Carousel.Item className="home-caro-img-wrap">
                <img
                    className={index == 0 ? "d-block w-100 home-caro-img car-zoom car-zoom-active" : "d-block w-100 home-caro-img car-zoom"}
                     src={src1}  //src="/images/banner1.jpeg"
                    //src="/images/banner1_full.jpeg" //progressive load seems to not work that well for first slide at least
                    // style={{filter: blur1 ? "blur(20px)" : "none", transition: blur1 ? "none" : "filter 0.3s ease-out"}} //enable for progressive image load
                    alt="First slide"
                    onLoad={() => firstSlideImageLoaded()}
                />
                <FadeIn transitionDuration="1200"><Carousel.Caption>
                    <h3>Stylish Three Piece Scrunch Set <Button onClick={() => history.push("/shop?name=Three Piece Scrunch Set - Mint Candy")}
                        variant="outline-light">SEE MORE</Button></h3>
                    <p>Long sleeve, thumb hole desing & Built-in Small Pockets.</p>
                </Carousel.Caption></FadeIn>
            </Carousel.Item>
            <Carousel.Item className="home-caro-img-wrap">
                <img
                    className={index == 1 ? "d-block w-100 home-caro-img car-zoom car-zoom-active" : "d-block w-100 home-caro-img car-zoom"}
                    src={src2}
                    alt="Second slide"
                />
                <FadeIn transitionDuration="1200"><Carousel.Caption>
                    <h3>Figure Sculped Scrunch Jumpsuit £35  <Button onClick={() => history.push("/shop?name=Figure Sculped Scrunch Jumpsuit - Watermelon Pink")}
                        variant="outline-light">SHOP NOW</Button></h3>
                    <p>In a selection of colours.</p>
                </Carousel.Caption></FadeIn>
            </Carousel.Item>
            <Carousel.Item className="home-caro-img-wrap">
                <img
                    className={index == 2 ? "d-block w-100 home-caro-img car-zoom car-zoom-active" : "d-block w-100 home-caro-img car-zoom"}
                    src={src3}
                    alt="Third slide"
                />

                <FadeIn transitionDuration="1200"><Carousel.Caption>
                    <h3>Mens PU Coated Slim Vest  £20  <Button onClick={() => history.push("/shop?name=Slim Vest - Black")}
                        variant="outline-light">SHOP NOW</Button></h3>
                    <p>Coating increases sweat and fat burn.</p>
                </Carousel.Caption></FadeIn>
            </Carousel.Item>
        </Carousel>
    );
};

export default connect()(HomeCarousel);
