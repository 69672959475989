import {getHeaders} from "../components/Api";
const requestProgramsType = 'REQUEST_PROGRAMS';
const receiveProgramsType = 'RECEIVE_PROGRAMS';
const initialState = { programs: [], isLoading: false };

export const programActionCreators = {
    requestPrograms: (url) => async (dispatch) => {
        dispatch({ type: requestProgramsType });
        const response = await fetch(url, {
            method: "GET",
            headers: getHeaders(),
        });
        const programs = await response.json();

        dispatch({ type: receiveProgramsType, programs });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestProgramsType:
            return {
                ...state,
                isLoading: true
            };
        case receiveProgramsType:
            return {
                ...state,
                programs: action.programs,
                isLoading: false
            };
        default:
            return state;
    }

    return state;
};
