import {getHeaders} from "../components/Api";
const requestTransactionType = 'REQUEST_TRANSACTION';
const receiveTransactionType = 'RECEIVE_TRANSACTION';
const initialState = { transaction: [], isLoading: false };

export const transactionActionCreators = {
    requestTransaction: (url, merchantID, password, crossReference) => async (dispatch) => {
        dispatch({ type: requestTransactionType });
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                MerchantID: merchantID,
                Password: password,
                CrossReference: crossReference,
                EchoAVSCheckResult : false,
                EchoCV2CheckResult : false,
                EchoThreeDSecureAuthenticationCheckResult : false,
                EchoCardType  : false ,
                EchoCardNumberFirstSix  : false,
                EchoCardNumberLastFour  : false,
                EchoCardExpiryDate   : false,
                EchoDonationAmount   : false
            }),
            headers: getHeaders(),
            /*"mode" : "no-cors"*/});
        const transaction = await response.json();
        dispatch({ type: receiveTransactionType, transaction });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestTransactionType:
            return {
                ...state,
                isLoading: true
            };
        case receiveTransactionType:
            return {
                ...state,
                transaction: action.transaction,
                isLoading: false
            };
        default:
            return state;
    }

    return state;
};
