import React from "react";
import {Button, Form} from 'react-bootstrap';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {getBaseUrl, getHeaders} from "./Api"
import InfoModal from "./InfoModal";
import ModalLoad from "./ModalLoad";

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const Order = (props) => {
    const [productOrders, setProductOrders] = React.useState([]);
    const [productsLoaded, setProductsLoaded] = React.useState(false);
    const [orderObject, setOrderObject] = React.useState(null);
    const [orderSubmitted, setOrderSubmitted] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorList, setErrorList] = React.useState(undefined);
    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        if (props.quantities != {} && props.products != [] ) {
            let i = 0;
            let productOrder = {};
            setProductOrders([]);
            setProductsLoaded(false);
            for (i = 0; i <= props.products.length-1; i++) {
                if(props.products[i].id in props.quantities) {
                    productOrder = {
                        product: props.products[i],
                        quantity: Number(props.quantities[props.products[i].id])
                    };

                    productOrders.push(productOrder);
                }
            }
            setOrderObject({...props.userInfo, productOrders});
            setProductsLoaded(true);
        }

        if(!orderSubmitted && productsLoaded && props.validated){
            setOrderSubmitted(true);
            submitOrder();
        }
    }, [props.product, props.quantities, props.userInfo, props.validated, error, errorList]);

    const goToCheckout = () => {
        setErrorList(undefined);
        setOrderSubmitted(false);
        setError(false);
        props.reset();
    }

    //remove if not using takepayments gateway
    const goToPay = (id, hashDigest, formAction, merchantID, amount, transactionDateTime, callbackUrl) => {
        history.push("/payment", {id: id, hashDigest: hashDigest, formAction: formAction, merchantID: merchantID,
            amount: amount, transactionDateTime: transactionDateTime, callbackUrl: callbackUrl, userInfo: props.userInfo});
    }

    const goToExternal = (link) => {
        window.open(link,"_self");
    }

    const submitOrder = () => {
        setIsLoading(true);
        fetch(getBaseUrl() + props.orderUrl, {
            method: 'POST',
            body: JSON.stringify(orderObject),
            headers: getHeaders(),
        }).then(response => response.json()).then(data => {
            setIsLoading(false);
            if(data.checkout == undefined) { setError(true); setErrorList(data); } else { goToExternal(data.checkout.checkout_page_url); }
            //goToPay(data.id, data.hashDigest, data.formAction, data.merchantID, data.amount, data.transactionDateTime, data.callbackUrl);
        }).catch((err) => {
                console.log(err);
                setIsLoading(false);
                setError(true);
            });
    };

    const failModal = () => {
        return error ? <InfoModal
            show={true} text={"Failed to transfer you to the payment gateway."} subText={"Get in contact if failure persists."}
            title={"Oops something went wrong.."} listMessages={errorList} callback={goToCheckout}/> : [];
    };

    const renderLoading = () => { return (isLoading && window.innerWidth < 690) ? <ModalLoad open={true}/> :[];};

    return (<div>{renderLoading()}
        <Button variant="outline-dark" className="btn btn-light btn-block" type="submit" //onClick={() => submitOrder()}
                disabled={props.cartItemCount == 0}>TO PAYMENT <FontAwesomeIcon style={{marginRight: '60px'}} icon={faLock}/>
                <i style={{marginRight: '8px', fontSize: 'small'}}>powered by </i><img style={{width: '100px'}}alt="squareup" src="./images/square.png"/>
        </Button>
        <Form.Text className="text-muted">
            Takes you to the secure Square payment gateway.
        </Form.Text>
        {failModal()}
    </div>);};

export default Order;

Order.defaultProps = {
    orderUrl: 'orders'
};

