import React from "react";
import Products from './Products';
import CartPop from './CartPop';
import checkBrowser from "./checkBrowser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {productActionCreators} from "../store/Products";
import {Spinner, Container,Row, Col, Breadcrumb} from "react-bootstrap";
import ProductDetails from "./ProductDetails";
import {getBaseUrl} from "./Api"
import Filter from "./Filter";
import FadeIn from "react-fade-in";
import * as QueryString from "query-string";
import {useHistory, withRouter} from "react-router-dom";
import ModalLoad from "./ModalLoad";

const Shop = (props) => {
    const [productCartCount, setProductCartCount] = React.useState({});
    const [cartProducts, setCartProducts] = React.useState({});
    const [products, setProducts] = React.useState([]);
    const [cartItemCount, setCartItemCount] = React.useState(Number('0'));
    const [isLocalStorageSupported, setIsLocalStorageSupported] = React.useState(true);
    const [triggerCartUpdate, setTriggerCartUpdate] = React.useState(false);
    const [visibleProducts, setVisibleProducts] = React.useState([]);
    const [colorsByNames, setColorsByNames] = React.useState([]);
    const [sizesByNames, setSizesByNames] = React.useState();
    const [displaySingle, setDisplaySingle] = React.useState(false);
    const [displayCart, setDisplayCart] = React.useState(false);
    const [singleProduct, setSingleProduct] = React.useState();
    const [singleProductSizes, setSingleProductSizes] = React.useState();
    const [breadcrumbSize, setBreadcrumbSize] = React.useState(70);
    const [gender, setGender] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [type , setType] = React.useState(null);
    const [style , setStyle] = React.useState(null);
    //const [urlProductId , setUrlProductId] = React.useState(null);
    const [ urlSearch, setUrlSearch ] = React.useState("");
    const history = useHistory();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setIsLocalStorageSupported(checkBrowser());
        if (props.products.length === 0) {
            props.requestProducts(getBaseUrl() + props.productUrl);
        }
        else if (products.length === 0) {
            setProducts(props.products);
            getSizes();
            getColors();
        } else { populateCart(); }

        if(props.displayCart != undefined){ gotoCart(); }

        if (QueryString != undefined && props.products.length != 0 && sizesByNames != undefined) {
            let UrlQueryStrings = history.location.search;
            const queryValues = QueryString.parse(UrlQueryStrings);
            if(queryValues.id != undefined && queryValues.id != "" && props.products.filter(p => p.id == queryValues.id).length > 0) {
                updateSelectedProduct(Number(queryValues.id),
                    sizesByNames.get(props.products.find(p => p.id == queryValues.id).name));
            } else if(queryValues.name != undefined && queryValues.name != "" && props.products.filter(p => p.name == queryValues.name).length > 0) {
                updateSelectedProduct(props.products.filter(p => p.name == queryValues.name)[0].id,
                    sizesByNames.get(props.products.filter(p => p.name == queryValues.name)[0].name));
            }else if (queryValues.id == undefined || queryValues.id == "") {
                setDisplaySingle(false);
            }
        }
        return history.listen(location => {
            if (history.action === 'PUSH') { //forward
                setUrlSearch(location.search);}

            if (history.action === 'POP') { //back
                setUrlSearch(location.search);} });
    }, [props.products, products.length, urlSearch]);

    const populateCart = () => {
        if(isLocalStorageSupported){
            let i = 0;
            let key = "";
            let count = 0;
            for (i = 0; i <= localStorage.length-1; i++) {
                key = localStorage.key(i);
                if(!isNaN(key)) {
                    productCartCount[Number(key)] = Number(localStorage.getItem(key));
                    count = (Number(count) + Number(localStorage.getItem(key)));
                }
            }

            setCartItemCount(Number(count));
            updateCartProducts();
        }
    }

    const updateTotalCartCount = () => {
        let i = 0;
        let key = "";
        let count = 0;
        for (i = 0; i <= localStorage.length-1; i++) {
            key = localStorage.key(i);
            if(!isNaN(key)) {
                count = (Number(count) + Number(localStorage.getItem(key)));
            }
        }

        setCartItemCount(Number(count));
        props.navbarCallback(Number(count));
    };

    const updateSelectedProduct = (id, sizes) => {
        setDisplaySingle(true);
        setBreadcrumbSize(100);
        setSingleProduct(products.find(prod => prod.id === id));
        setSingleProductSizes(sizes);
    };

    const cartCallback = (productId, count, clearProduct, startFromZero) => {
        if(clearProduct) {
            if(productCartCount[productId] != undefined){
                setCartItemCount(Number(Number(cartItemCount) - productCartCount[productId]));
                props.navbarCallback(Number(Number(cartItemCount) - productCartCount[productId]));
            }

            delete productCartCount[productId]
            if(isLocalStorageSupported) { localStorage.removeItem(productId.toString()); }
            updateCartProducts();
            return;
        }

        if (productCartCount[productId] == undefined || startFromZero) {
            productCartCount[productId] = Number(count);
            if(isLocalStorageSupported) { localStorage.setItem(productId.toString(), count.toString());}
        }
        else {
            if(isLocalStorageSupported) {localStorage.setItem(
                productId.toString(),
                (Number(productCartCount[productId]) + Number(count)).toString());}
            productCartCount[productId] = (Number(productCartCount[productId]) + Number(count));
        }

        updateTotalCartCount();
        updateCartProducts();
    };

    const updateCartProducts = () => {
        let i;
        for (var key in productCartCount) {
            for (i = 0; i <= products.length-1; i++) {
                if (products[i].id == key){
                    cartProducts[key] = products[i];
                }
            }
        }

        triggerCartUpdate ? setTriggerCartUpdate(false) : setTriggerCartUpdate(true);
    };

    const renderLoading = () => {
        return <div className='clearfix text-center'>
            {(props.isLoading && window.innerWidth >= 690) ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner> : (props.isLoading && window.innerWidth < 690) ? <ModalLoad open={true}/>:[]}
        </div>;
    };

    const getSizes = () => {
        const map = new Map();
        for (const item of props.products) {
            if(!map.has(item.name)){
                map.set(item.name, [{id: item.id, size: item.size, stock: item.stock}]);
                visibleProducts.push(item);
            } else {
                map.get(item.name).push({id: item.id, size: item.size, stock: item.stock});
            }
        }

        setSizesByNames(map);
    };

    const getColors = () => {
        const map = new Map();
        for (const item of props.products) {
            if(!map.has(item.name.split('-')[0])){
                map.set(item.name.split('-')[0], [{id: item.id, color: item.colour}]);
            } else if(map.get(item.name.split('-')[0])[0].color != item.colour) {
                map.get(item.name.split('-')[0]).push({id: item.id, color: item.colour});
            }
        }

        setColorsByNames(map);
    };

    const gotoCart = () => {
        setDisplayCart(true);
        setDisplaySingle(false);
        setBreadcrumbSize(100);
        //setUrlProductId(null);
    };

    const displayProduct = () => {
        return <ProductDetails product={singleProduct} gotoCart={gotoCart} colorsByNames={colorsByNames}
                          sizesByName={singleProductSizes} cartCallback={cartCallback} />;
    };

    const displayProductList = () => {
        return <Products searchTerm={props.searchTerm} products={products} gotoCart={gotoCart} colorsByNames={colorsByNames}
                         sizesByNames={sizesByNames} cartCallback={cartCallback}
                         gender={gender} category={category} style={style} type={type} />;
    };

    const filter = (gender, category, style, type) => {
        setGender(gender); setCategory(category); setStyle(style); setType(type);
    };

    return (<div style={{width: '100%'}}>
        {renderLoading()}
        <Container><Row><Col><Breadcrumb className="nospace_element" style={{maxWidth: `${breadcrumbSize}vw`}}>
            <Breadcrumb.Item href="#home">Home</Breadcrumb.Item>
            {displayCart && <Breadcrumb.Item active>Basket</Breadcrumb.Item>}
            {!displaySingle && !displayCart && <Breadcrumb.Item active>New In</Breadcrumb.Item>}
            {displaySingle && <><Breadcrumb.Item href="shop">New In</Breadcrumb.Item><Breadcrumb.Item active href="#shop">{singleProduct.name}</Breadcrumb.Item></>}
        </Breadcrumb></Col><Col style={{display: `${breadcrumbSize == 70 ? 'block' : 'none'}`}}><Filter filter={filter} products={products}/></Col></Row></Container>
        {displayCart && <FadeIn><CartPop productCartCount={productCartCount} cartCallback={cartCallback} cartProducts={cartProducts} triggerCartUpdate={triggerCartUpdate} products={products} cartItemCount={cartItemCount}/></FadeIn>}
        {sizesByNames != undefined && !displaySingle && !displayCart && displayProductList()}
        {sizesByNames != undefined && displaySingle && singleProduct != undefined && singleProductSizes != undefined && displayProduct()}
    </div>);};
export default connect(
    state => state.products,
    dispatch => bindActionCreators(productActionCreators, dispatch)
)(withRouter(Shop));

Shop.defaultProps = {
    productUrl: 'products'
};
