import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {IconButton, Tooltip} from "@material-ui/core";

const CountInput = (props) => {
    const [counter, setCounter] = React.useState( 0);
    const [displayCounter, setDisplayCounter] = React.useState( false);
    const [maxDisable, setMaxDisable] = React.useState( false);

    const handleIncrement = () => {
        if((counter + 1) == props.max){
            setMaxDisable(true);
        }
        setDisplayCounter(true);
        setCounter(props.value + 1);
        props.updateDropQuantity(props.value + 1);
    };

    const handleDecrement = () => {
        setMaxDisable(false);
        if((counter - 1) == 0){
            setDisplayCounter(false);
        }
        setCounter(props.value - 1);
        props.updateDropQuantity(props.value - 1);
    };

    return (
        <ButtonGroup size="small" aria-label="small outlined button group" style={{height: '38px', marginTop: '4px', marginLeft: '50px', minWidth: '70px'}}>
            <Tooltip title="Add item" aria-label="Add"><Button disabled={props.disabled || maxDisable} onClick={() => handleIncrement()}>+</Button></Tooltip>
            {displayCounter && <Button disabled>{counter}</Button>}
            {displayCounter && <Tooltip title="Remove item" aria-label="Remove"><Button disabled={props.disabled} onClick={() => handleDecrement()}>-</Button></Tooltip>}
        </ButtonGroup>
    )
};

export default CountInput;


// const CountInput = (props) => {
//     const update = (plus) => {
//         let newQuantity = props.quantity;
//         if (plus && props.quantity < 100) {
//             newQuantity =  Number(Number(newQuantity) + 1);
//         }
//         else if (!plus && props.quantity > 0) {
//             newQuantity = Number(Number(newQuantity) - 1);
//         }
//
//         props.updateDropQuantity(newQuantity);
//     };
//
//     return (<div className="number">
//         <span onClick={() => update(false)} className="minus">-</span>
//         <input className="number_inp" type="text" value={props.quantity}/>
//         <span onClick={() => update(true)} className="plus">+</span>
//     </div>);
// };
// export default CountInput;

