import React from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap';
import NavMenu from './NavMenu';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SimpleReactFooter from "simple-react-footer";
import FadeIn from "react-fade-in";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {useHistory, withRouter} from "react-router-dom";
import CookieModal from './CookieModal';
import Footer from './Footer';

const Layout = (props) => {
    const [showModal, setShowModal] = React.useState(false);
    const [modalShown, setModalShown] = React.useState(false);

    React.useEffect(() => {
    }, [props.cartCount]);

    const cookieCon = () => {
        return !modalShown && <CookieModal toggleCookies={props.toggleCookies} />
    };

    return (<div style={{height: '100%'}}>
        <Container fluid style={{minHeight: `${props.location.pathname == '/workout'?
                (window.innerWidth < 690 ? '350px' : '650px'):'0px'}`}}>
            {cookieCon()}
            <Row>
                <NavMenu cartCount={props.cartCount} products={props.products} searchCallback={props.searchCallback}/>
            </Row>
            <Row>
                {props.children}
                {/*{React.Children.map(props.children, child => {*/}
                {/*    return React.cloneElement(child, {navbarCallback: navbarCallback})*/}
                {/*})}*/}
            </Row>

        </Container><FadeIn className="bg-second" delay={1000}>{props.location.pathname != '/workout' ? <Footer/>:[]}</FadeIn></div>
    );
};

export default withRouter(Layout);
