import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import {Card, ListGroupItem, ListGroup} from 'react-bootstrap';
import FadeIn from "react-fade-in";

const CookiePolicy = (props) => {
    return (<div style={{width: '100%'}}><Card className="card mb-3">
        <Card.Header>Our Cookie Policy</Card.Header>
        <FadeIn>
            <Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroupItem><Card.Title>What Are Cookies</Card.Title>
            <Card.Text>Most professional websites use cookies, which are tiny files that are downloaded to your computer, to improve your user experience.
            </Card.Text></ListGroupItem>
                    <ListGroupItem><Card.Title>How We Use Cookies</Card.Title>
            <Card.Text>We don't currently use any cookies for the operation of the functionality of this site,
                but our third party payment gateway does.</Card.Text></ListGroupItem>
                    <ListGroupItem><Card.Title>Disabling Cookies</Card.Title>
            <Card.Text>You can disable the use of cookies by changing settings in your browser (see your browser Help for this).
                <br/>This will affect the payment processing on this website,
                and will likely affect the functionality of many other sites you visit, therefore it is recommended that you keep cookies enabled.</Card.Text></ListGroupItem>
                    <ListGroupItem><Card.Title>The Cookies We Set</Card.Title>
            <Card.Text>We don't currently set any cookies.</Card.Text></ListGroupItem>
                    <ListGroupItem><Card.Title>Third Party Cookies</Card.Title>
                        <Card.Text>The payment gateway sets a number of cookies to enable processing of card payments.</Card.Text>
                    </ListGroupItem>
            </ListGroup>
            </Card.Body></FadeIn>
    </Card></div>);
};

export default CookiePolicy;


