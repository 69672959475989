import {getBaseUrl} from "../components/Api"
const requestMusclesType = 'REQUEST_MUSCLES';
const receiveMusclesType = 'RECEIVE_MUSCLES';
const initialState = { info: null, isLoading: false };

export const muscleActionCreators = {
    requestMuscleInfo: () => async (dispatch) => {
        dispatch({ type: requestMusclesType });

        const url = getBaseUrl() + `muscles`;
        const response = await fetch(url);
        const info = await response.json();
        dispatch({ type: receiveMusclesType, info });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestMusclesType:
            return {
                ...state,
                isLoading: true
            };
        case receiveMusclesType:
            return {
                ...state,
                info: action.info,
                isLoading: false
            };
        default:
            return state;
    }
};
