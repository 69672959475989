import React from 'react';
import {Button, Container, Row, Collapse, Fade, Col} from 'react-bootstrap';
import FadeIn from "react-fade-in";
import checkBrowser from "./checkBrowser";
const App = (props) => {
    const [show, setShow] = React.useState(false);
    const [done, setDone] = React.useState(false);

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if(checkBrowser() &&
            (localStorage.getItem('cookiePolicyShown') == undefined ||
                localStorage.getItem('cookiePolicyShown') == null)) {
            setOpen(true);
        }
    }, []);

    const popupAddressed = (on) => {
        setOpen(false);
        if(checkBrowser()) {
            localStorage.setItem('cookiePolicyShown', 'yes');
        }

        props.toggleCookies(on);
    }

    return (<Collapse in={open} timeout="1000"><div className="cookie-policy">
        <Container>
            <Row className="wide-cookie">
                <Col><p className="put-left" style={{marginTop: '20px', color: 'white'}}><FadeIn delay={400}>
                    We use cookies to ensure that we give you the best experience on our website
                </FadeIn></p></Col>
                <Col><p style={{marginTop: '20px'}}><FadeIn delay={500}>
                    <a style={{color: 'indianred'}} href="/cookiepolicy">cookie policy.</a></FadeIn></p></Col>
                <Col><p className="put-right"><FadeIn delay={600}>
                    <Button variant="outline-light" style={{marginTop: '15px', marginRight: '15px'}}
                            onClick={() => popupAddressed(false)}>REJECT</Button>
                </FadeIn></p><p className="put-right"><FadeIn delay={600}>
                    <Button variant="outline-light" style={{marginTop: '15px', marginRight: '15px'}}
                            onClick={() => popupAddressed(true)}>Accept</Button>
                </FadeIn></p></Col>
            </Row>
            <Row className="mobile-cookie">
                <Col><p className="put-left" style={{marginTop: '20px', color: 'white'}}><FadeIn delay={400}>
                    We use cookies to ensure that we give you the best experience on our website
                </FadeIn></p></Col>
                <Col><p style={{marginTop: '20px', float: 'right'}}><FadeIn delay={500}>
                    <a style={{color: 'indianred'}} href="/cookiepolicy">cookie policy.</a></FadeIn></p></Col>
                <Col><p className="put-right"><FadeIn delay={600}>
                    <Button variant="outline-light" style={{marginTop: '15px', marginRight: '15px'}}
                            onClick={() => popupAddressed(false)}>REJECT</Button>
                </FadeIn></p><p className="put-right"><FadeIn delay={600}>
                    <Button variant="outline-light" style={{marginTop: '15px', marginRight: '15px'}}
                            onClick={() => popupAddressed(true)}>Accept</Button>
                </FadeIn></p></Col>
            </Row>
        </Container>
    </div></Collapse>);
};

export default App;