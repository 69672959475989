import React from 'react';
import { connect } from 'react-redux';
import { Button, CardColumns, Card, Form, Col, Row, Container } from 'react-bootstrap';
import ProductGallery from './ProductGallery';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import {IconButton, Tooltip} from "@material-ui/core";
import NumericInput from 'react-numeric-input';
import {useHistory} from "react-router-dom";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import FadeIn from "react-fade-in";
import CountInput from "./CountInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";

const ProductDetails = (props) => {
    const [productDropQuantity, setProductDropQuantity] = React.useState(Number('0'));
    const [inCart, setInCart] = React.useState(false);
    const [selectedSizeId, setSelectedSizeId] = React.useState();
    const history = useHistory();

    const updateDropQuantity = (count) => {
        if (count != null) {
            setProductDropQuantity(Number(count));
        }
    };

    const nextImage = () => {
    }

    const updateinCart = (value) => {
        setInCart(value);
        console.log(props.product.id + "  " + productDropQuantity + "  " + !value + " sizeid: " + (selectedSizeId == undefined? props.sizesByName[0].id : selectedSizeId));
        props.cartCallback((selectedSizeId == undefined? props.sizesByName[0].id : selectedSizeId), productDropQuantity, !value, false);
        if(value == false){
            setProductDropQuantity(Number('0'));
        }
    };

    const calculateDiscount = (price, discount, stock) => {
        if (discount != 0){
            return <Card.Text><del style={{color: "grey"}}>£{price}</del> £{(Number(price) * (100 - Number(discount)) * Number('0.01')).toFixed(2)}
            <small style={{color: "green"}}>&nbsp;-{discount}%</small>{stock == 0 ? <b className="second-col">&nbsp;&nbsp;OUT OF STOCK</b> : []}</Card.Text>;
        }

        return <Card.Text>£{price} {stock == 0 ? <b className="second-col">&nbsp;&nbsp;OUT OF STOCK</b> : []}</Card.Text>;
    };

    const  sizeChanged = (event) => {
        setSelectedSizeId(event.target.value);
    };

    const goToGivenProduct = (id) => {history.push("/shop?id=" + id);}

    const availColours = () => {
        const availableColours = props.colorsByNames.get(props.product.name.split('-')[0])
            .filter((v,i,a)=>a.findIndex(t=>(t.color === v.color))===i).map((idColor, index) =>
            <IconButton id={index} aria-label="color" className="icon-button" style={{color: idColor.color}}
                        onClick={() => goToGivenProduct(idColor.id)}><ColorLensIcon/></IconButton>
        );
        return availableColours;
    };

    return (<CardColumns>
        <FadeIn transitionDuration="1000"><Card>
            <ProductGallery src1={props.product.pictureUrl}/>
            {/*<Card.Img className="link_element"  onClick={() => nextImage()} variant="top" src={props.product.pictureUrl} />*/}
        </Card></FadeIn>
        <FadeIn delay="500" transitionDuration="1000"><Card>
        <Card.Header className="link_element">
            {props.product.name}
        </Card.Header>
        <Card.Body>
            {/*<Card.Title>{props.product.name}</Card.Title>*/}
            {/*<ProductImage productId={props.product.id} />*/}
            <Card.Title>Product Details</Card.Title>
            <Card.Subtitle className="mb-2 text-muted"><ul>{props.product.description.split('.').map(line => <li>{line}</li>)}</ul></Card.Subtitle>
            <Card.Text className="mb-2">Material:&nbsp; <div className="text-muted">{props.product.material}</div></Card.Text>
            <Card.Text className="mb-2">Fit:&nbsp; <div className="text-muted">{props.product.fit}</div></Card.Text>
            {calculateDiscount(props.product.price, props.product.discount, props.product.stock)}
            <Container>
                { inCart ? [] : <Row>
                    <Col className="nospace_element"><Form.Label>Quantity:</Form.Label>
                        <CountInput max={props.product.stock} value={productDropQuantity} updateDropQuantity={updateDropQuantity} disabled={props.product.stock == 0}/></Col>
                    {/*<Col className="nospace_element">*/}
                    {/*    <Form.Label>Quantity:</Form.Label>*/}
                    {/*    <input id={props.product.id} type="number" min="0" max={props.product.stock} value={productDropQuantity}*/}
                    {/*           disabled={props.product.stock == 0} className="form-control quantity_drop" onChange={event => updateDropQuantity(event.target.value)}/></Col>*/}
                    <Col className="nospace_element">
                        <Form.Label>Size:</Form.Label>
                        <Form.Control as="select" disabled={props.product.stock == 0} onChange={sizeChanged} className="my-1 mr-sm-2" id="inlineFormCustomSelectPref" custom>
                        {Object.entries(props.sizesByName).map(([key,value], index) =>
                            <option disabled={value.stock == 0} value={value.id}>{value.size}</option>)}
                    </Form.Control></Col>
                    <Col className="nospace_element"><Tooltip title="Add to basket" aria-label="Add">
                        <IconButton aria-label="add_cart" className="icon-button disabled_element justify-bottom"
                                                                 style={{color: `${Number(productDropQuantity) <= Number('0') ? 'grey':'green'}`}}
                                                                 disabled={Number(productDropQuantity) <= Number('0')}
                                                                 onClick={() => updateinCart(true)}><AddShoppingCartIcon /></IconButton></Tooltip></Col>

                </Row> }
                <div class="col-12">
                    { inCart ? <><Tooltip title="Remove from basket" aria-label="Remove"><IconButton aria-label="add_cart" className="icon-button" style={{color: "red"}}
                                           onClick={() => updateinCart(false)}><RemoveShoppingCartIcon/></IconButton></Tooltip>
                        <Button style={{marginLeft: '10px'}} onClick={() => props.gotoCart()} variant="outline-dark">GO TO BASKET</Button></>: [] }
                </div>
            </Container>
        </Card.Body>
        <Card.Footer>Available Colours
            {availColours()}
        </Card.Footer>
    </Card></FadeIn></CardColumns>);};

export default connect()(ProductDetails);
