import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './body.css';

const MuscleLabel = (props) => {
    return (<div className="App">
        <span className={"muscle_label " + (props.displayInfo ? 'blink_me' : null)} style={{ ...props.getTipPosition(props.hoveredArea) }}>
            {props.hoveredArea && props.hoveredArea.name}
            {/*{props.displayInfo && <FontAwesomeIcon icon={faHandPointLeft} />}*/}
            </span></div>);
}

export default connect()(MuscleLabel);