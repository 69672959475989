import {getHeaders} from "../components/Api";
const requestPostsType = 'REQUEST_POSTS';
const receivePostsType = 'RECEIVE_POSTS';
const initialState = { posts: [], isLoading: false };

export const postActionCreators = {
    requestPosts: (url) => async (dispatch) => {
        dispatch({ type: requestPostsType });
        const response = await fetch(url, {
            method: "GET",
            headers: getHeaders(),
            });
        const posts = await response.json();

        dispatch({ type: receivePostsType, posts });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case requestPostsType:
            return {
                ...state,
                isLoading: true
            };
        case receivePostsType:
            return {
                ...state,
                posts: action.posts,
                isLoading: false
            };
        default:
            return state;
    }

    return state;
};
