import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './Reducers'

function configureStore(history, initialState) {
  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];

  const store = createStore(
      createRootReducer(history), // root reducer with router state
      initialState,
      compose(
          applyMiddleware(
              routerMiddleware(history), // for dispatching history actions
              ...middleware,
              ...enhancers
          ),
      ),
  )

  return store
}

export default configureStore;
