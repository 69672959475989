import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import * as Products from "./Products";
import * as Posts from "./Posts";
import * as Areas from "./Areas";
import * as Muscles from "./Muscles";
import * as Programs from "./Programs";
import * as Cookie from "./Cookie";

const reducers = {
    products: Products.reducer,
    posts: Posts.reducer,
    areas: Areas.reducer,
    muscles: Muscles.reducer,
    programs: Programs.reducer
    // cookie: Cookie.reducer
};

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    ...reducers
})
export default createRootReducer