import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faSmileBeam, faFrown, faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import 'font-awesome/css/font-awesome.min.css';
import {Form, Card, Container, Button, Spinner } from 'react-bootstrap';
import FadeIn from "react-fade-in";
import {getBaseUrl, getHeaders} from "./Api"
import { useForm } from 'react-hook-form';
import ModalLoad from "./ModalLoad";

const Contact = (props) => {
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [text, setText] = React.useState("");
    const [responseStatus, setResponseStatus] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { register, handleSubmit, errors } = useForm();

    React.useEffect(() => {
    }, [responseStatus])

    const goToContact = () => {
        setResponseStatus(null);
    }

    const submit = () => {
        setIsLoading(true);
        fetch(getBaseUrl() + props.emailUrl, {
            method: 'POST',
            body: JSON.stringify({
                from: email,
                subject: name,
                text: text + "    |||| sender email: " + email + " ||||",
                to: ""
            }),
            headers: getHeaders(),
        }).then(response => {setIsLoading(false); setResponseStatus(response.status); }).
        catch((err) => {
            setIsLoading(false);
            setResponseStatus(-10);
        });
    };

    const renderLoading = () => {
        return (isLoading && window.innerWidth >= 690) ?
            <div className='clearfix text-center'>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div> : (isLoading && window.innerWidth < 690) ? <ModalLoad open={true}/>:[];
    };

    const onSubmit = (data) => {
        submit()
    };

    const thankYou = () => {
        return <div className="center-me">
            {(responseStatus == 201) ?
                <><FadeIn><Card.Title>Thank you for getting in touch <FontAwesomeIcon icon={faSmileBeam} className="second-col"/></Card.Title>
                <Card.Subtitle>We will get back to you as soon as we can!</Card.Subtitle></FadeIn></> : []}
        </div>;
    };

    const sorry = () => {
        return <div className="center-me">
            {(responseStatus != null && responseStatus != 201) ?
                <><FadeIn><Card.Title>There was a problem sending your message <FontAwesomeIcon icon={faFrown} className="second-col"/>
            </Card.Title><Form.Text className="text-muted" style={{marginBottom:'15px'}}>Make sure you filled out all fields correctly.</Form.Text>
                    <Card.Subtitle>Please try again.</Card.Subtitle>
                    <Card.Text>Alternatively get in touch at <i><a className="required-field" href="mailto:powa.ldn@gmail.com">powa.ldn@gmail.com</a></i></Card.Text></FadeIn>
                    <FadeIn delay={500}>
                        <Button style={{marginTop: '30px'}}
                                onClick={() => goToContact()}
                                variant="outline-dark">BACK TO FORM <FontAwesomeIcon icon={faChevronCircleLeft}/></Button></FadeIn></> : []}
        </div>;
    };

    const formFields = () => {
        return <>
            {(responseStatus == null) ?
                <Form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>E-Mail <b className="required-field">*</b></Form.Label>
                        <Form.Control required type="email" placeholder="name@example.com" ref={register({required: true, pattern: /^\S+@\S+$/i})}
                                      value={email} onChange={(e) => { setEmail(e.target.value); }}/>
                    </Form.Group>
                    <Form.Group >
                        <Form.Label>Name <b className="required-field">*</b></Form.Label>
                        <Form.Control required value={name} ref={register({required: true, maxLength: 100})}
                                      onChange={(e) => { setName(e.target.value); }}/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Message <b className="required-field" >*</b></Form.Label>
                        <Form.Control required  as="textarea" rows={3} value={text} ref={register({required: true, minLength: 20, maxLength: 1000})}
                                      onChange={(e) => { setText(e.target.value); }}/>
                    </Form.Group>
                    <FadeIn><Button disabled={isLoading ? "disabled" : ""} type="submit" variant="outline-dark">SUBMIT <FontAwesomeIcon icon={faPaperPlane}/></Button></FadeIn>
                </Form> : <div style={{minHeight: '30vh'}}></div>} </>;
    };

    return (<div style={{width: '100%', minHeight: '65vh'}}><Card className="card mb-3">
        <Card.Header>Contact Us</Card.Header>
        <Card.Body><Container>
            {sorry()}
            {thankYou()}
            {renderLoading()}
            {formFields()}
        </Container></Card.Body>
    </Card></div>);
};
export default Contact;

Contact.defaultProps = {
    emailUrl: 'email'
};

