import React from "react";
import PromoCode from './PromoCode';
import OverlayButton from './OverlayButton';
import {Button, Card, Container, Row, Col } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faListAlt, faLock} from "@fortawesome/free-solid-svg-icons";
import Cancel from "@material-ui/icons/Cancel";
import {useHistory} from "react-router-dom";

const CartPop = (props) => {
    const [products, setProducts] = React.useState([]);
    const [cartContentLoaded, setCartContentLoaded] = React.useState(false);
    const [productDropQuantity, setProductDropQuantity] = React.useState(Number('0'));
    const [enableDropForItem, setEnableDropForItem] = React.useState(-1);
    const [promo, setPromo] = React.useState(1);
    const [promoCode, setPromoCode] = React.useState("");
    const deliveryPrice = 3.20;
    const history = useHistory();
    const goToCheckout = () => {
        history.push("/checkout", {products: products, quantities: props.productCartCount, cartItemCount: props.cartItemCount, promoCode: promoCode});
    }

    React.useEffect(() => {
        if (Object.keys(props.productCartCount).length != 0 && Object.keys(props.cartProducts).length != 0) {
            setCartContentLoaded(true);
        }
        else{
            setCartContentLoaded(false);
        }

        if(props.products != undefined && props.products != []) {
            setProducts(props.products);
        }

    }, [props.triggerCartUpdate]);

    const updateDropQuantity = (count) => {
        if (count != null) {
            setProductDropQuantity(Number(count));
        }
    };

    const deliveryRow = (total) => {
        return <Row className="row_underline center-content center-me space-on-top">
            <Row className="no-pads"><Col>Delivery (Royal Mail) <b>£{deliveryPrice.toFixed(2)}</b></Col></Row>
            <Row style={{marginBottom: '10px'}} className="text-muted"><Col><i>Delivery in 2 to 3 working days including Saturdays.</i></Col></Row></Row>;
    };

    const countUpdater = (count, key) => {
        return (enableDropForItem == key ?
            <React.Fragment>
                <input style={{width: '55px'}}
                    type="number"
                    min="0"
                    max="100"
                    value={productDropQuantity == 0 ? "" : productDropQuantity}
                    className="form-control"
                    onChange={event => updateDropQuantity(event.target.value)}/>
                    <IconButton
                        aria-label="check"
                        className="icon-button"
                        onClick={() => {
                            setEnableDropForItem(-1);
                            productDropQuantity == 0 ? deleteItem(key) : props.cartCallback(key, productDropQuantity, false, true)}}>
                        <CheckCircleOutlineIcon />
                    </IconButton>
            </React.Fragment> : count);
    };

    const calculateDiscount = (price, discount, count, key) => {
        return discount != 0 ?
            <React.Fragment><Row>Quantity: {countUpdater(count, key)}</Row>
                <Row><del style={{color: "grey"}}>£{price}</del>&nbsp;£{(Number(price) * (100 - Number(discount)) * Number('0.01')).toFixed(2)} per item  <small style={{color: "green"}}>&nbsp;(-{discount}%)</small></Row>
                <Row><b>Total: £{enableDropForItem == key ? '-' : ((Number(price) * (100 - Number(discount)) * Number('0.01')) * countUpdater(count, key)).toFixed(2)}</b></Row></React.Fragment> :
            <React.Fragment><Row>Quantity: {countUpdater(count, key)}</Row>
                <Row>£{price} per item</Row>
                <Row><b>Total: £{enableDropForItem == key ? '-' : (price * countUpdater(count, key)).toFixed(2)}</b></Row></React.Fragment>;
    };

    const calculateTotal = () => {
        let total = deliveryPrice;
        Object.entries(props.productCartCount).map(([key,value], index) =>
             {total =
                 Number(total) +
                 Number(value) *(Number(props.cartProducts[Number(key)].price) * (100 - Number(props.cartProducts[Number(key)].discount)) * Number('0.01'))}
        );

        return total;
    };

    const deleteItem = (key) => { props.cartCallback(key, 0, true, false); };

    const updateItem = (key) => {
        setEnableDropForItem(key); //triggers re-render with number drop-down for item with key
        setProductDropQuantity(props.productCartCount[key]);
    };

    const promoCallback = (code, discount) => {
        setPromo(discount);
        setPromoCode(code);
    };

    const clearPromos = () => {
        setPromo(1);
        setPromoCode("");
        let i = 0;
        let item;
        let key = "";
        const removeList = [];
        for (i = 0; i <= localStorage.length-1; i++) {
            key = localStorage.key(i);
            if(key.startsWith("promo-")) { removeList.push(key);}
        }

        for(item in removeList){
            localStorage.removeItem(removeList[item]);
        }
    }

    const total = () => {
        return promo == 1 ? <h5 style={{textAlign: "center", marginTop: '10px'}} className="card-title cart-total">Total: £{calculateTotal().toFixed(2)}</h5> :
            <div className="search_row"><del style={{color: "grey"}}><h5 className="card-title cart-total">£{calculateTotal().toFixed(2)}</h5></del>&nbsp;&nbsp;
                <h5 className="card-title cart-total">Total: £{(calculateTotal() - (calculateTotal() * promo)).toFixed(2)} &nbsp;&nbsp;<small ><i className="text-muted">
                    with code:</i>&nbsp; <b style={{color: "indianred"}}>{promoCode}</b></small>
                    <IconButton onClick={() => clearPromos()} style={{fontSize: "large"}} className="icon-button"><Cancel/>
                </IconButton></h5></div>;
    };

    const productRow = (key, value, index)  => {
        return <Row className="row_underline">
            <Col className="cart-image">
                <img className="thumbnail_cart link_element" alt="product image"
                     src={props.cartProducts[Number(key)].pictureUrl.split('.').join('1.').substring(1)} /></Col>
            <Col style={{marginRight: '7px'}}>
                <Row>{props.cartProducts[Number(key)].name}</Row>
                <Row>Size: {props.cartProducts[Number(key)].size}</Row>
            </Col>
            <Col>
                {calculateDiscount(props.cartProducts[Number(key)].price, props.cartProducts[Number(key)].discount, value, key)}
            </Col>
            <IconButton disabled={enableDropForItem != -1} aria-label="edit" onClick={() => updateItem(key)} style={{fontSize: "large"}} className="icon-button cart-button">
                <EditIcon/>
            </IconButton>
            <OverlayButton text={"Remove"} overlayText={"Remove item"} overlayBodyText={"Remove this item from the basket?"} func={deleteItem} funcParam={key}/>
            {/*<Button variant="danger" disabled={enableDropForItem != -1} onClick={() => updateItem(key)}>Update</Button>*/}
        </Row>;
    }


    return <Card class="card mb-3">
        <Card.Header>{props.cartItemCount} Item(s):</Card.Header>
        <Card.Body className="cart-body">
            {/*<Card.Subtitle className="mb-2 text-muted">{props.cartItemCount} Items:</Card.Subtitle>*/}
            <Container>
            {cartContentLoaded && Object.entries(props.productCartCount).map(([key,value], index) =>
                productRow(key, value, index)
            )}
                {props.cartItemCount > 0 ? deliveryRow():[]}
            </Container>
            {props.cartItemCount > 0 ? total():[]}
            {/*{products.length > 0 && <Order products={products} quantities={props.productCartCount} cartItemCount={props.cartItemCount}/>}*/}
            {products.length > 0 && <PromoCode promoCallback={promoCallback} clearPromos={clearPromos}/>}
            {products.length > 0 && <Button className="center-content checkout-button-cart" disabled={props.cartItemCount == 0}
                variant="outline-dark" onClick={() => goToCheckout()}>
                <FontAwesomeIcon style={{marginRight: '8px'}}icon={faLock}/> CHECKOUT SECURELY</Button>}
        </Card.Body>
        </Card>;
};
export default CartPop;

