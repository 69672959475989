import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {Spinner} from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ModalLoad(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if(props.open != open){ setOpen(props.open); }
        setOpen(props.open);
    }, [open]);

    const handleClose = () => { setOpen(false); };

    return (
        <div style={{minWidth: '100% !import', minHeight: '100% !important'}}>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                {props.text == undefined ?
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner> :
                    <table style={{width:"100%", alignContent: 'center', alignItems: 'center' ,textAlign: 'center'}}>
                    <tr>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </tr>
                    <tr>
                        <div className="loading-dots">{props.text}, please wait</div>
                    </tr>
                </table>}
            </Backdrop>
        </div>
    );
}